<div
  class="pagination w-full"
  style="
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #e9ecec;
    background: white;
  "
>
  <label>
    <div class="flex bg-white" style="border-radius: 100px">
      <ng-container>
        <button class="H10-14-400 my-text-dark" style="padding: 0 16px">
          Showing from
          <select #selection  [value]="paginatorState.itemsPerPage"  (change)="changeItemsPerPage(selection.value)">
            <option value=5>5</option>
            <option value=10>10</option>
            <option value=20>20</option>
          </select>
          to {{ paginatorState.totalCount }}
        </button>
      </ng-container>
    </div>
  </label>
  <div class="flex bg-white" style="border-radius: 100px">
    <button
      class="left-btn H9-16-500 text-white"
      [disabled]="!hasPrevFunc()"
      (click)="prevPage()"
    >
      Previous
    </button>
    <ng-container
      *ngFor="
        let items of [].constructor(paginatorState.lastPage);
        let i = index;
        let first = first;
        let last = last
      "
    >
      <button
        class="my-text-gray H9-16-500"
        style="padding: 0 16px; border-right: 2px solid var(--color-text-gray)"
        [style]="first ? 'border-right: 2px solid var(--color-text-gray);' : ''"
        [style]="last ? 'border-right: 0px !important;' : ''"
        [ngClass]="i + 1 === paginatorState.page ? 'active' : ''"
        (click)="setPage(i+1)"
      >
        {{ i + 1 }}
      </button>
    </ng-container>
    <button
      class="right-btn H9-16-500 text-white"
      [disabled]="!hasNextFunc()"
      (click)="nextPage()"
    >
    Next page
    </button>
  </div>
</div>
