import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {PageSizes, GenericPaginatorState} from '../../models/paginator.model';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit , OnDestroy{
  @Input({required: true}) paginatorState: GenericPaginatorState;
  @Input() showLoading: boolean = true;
  @Input() showPageSize: boolean = true;
  @Input() showPages: boolean = true;
  @Input() showPageInfo: boolean = true;
  @Input() isLoading: any;
  @Output() paginateChange: EventEmitter<GenericPaginatorState> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
  }


  pageChange(num: number) {
    this.paginatorState.page = num;
    this.paginateChange.emit(this.paginatorState);
  }

  sizeChange() {
    this.paginatorState.itemsPerPage = Number(this.paginatorState.itemsPerPage);
    this.paginatorState.page = 1;
    this.paginateChange.emit(this.paginatorState);
  }

  ngOnDestroy(): void {
    this.paginatorState.itemsPerPage = PageSizes[2];
  }
}
