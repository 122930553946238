import {ROLE_ADMIN, ROLE_PATIENT, ROLE_PROFESSIONAL} from './roles';

/**
 * Class to manage the configurations in the control of access to components via URL
 *
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export class AccessControl {
  /**
   * Allows you to configure the route and URL to redirect to if access is denied.
   * The path property specifies the route path, while the url property specifies the full URL to redirect to.
   */
  public static REDIRECT_ON_ACCESS_DENIED_ROUTE: { path: string, url: string } = {
    path: 'dashboard',
    url: '/dashboard',
  };
  /**
   * It allows to determine if the authenticated user in case of not having any value can access or not, by default it is false.
   */
  public static GRANT_ACCESS_IF_USER_HAS_NO_ROLE = false;
  /**
   * Enable or disable access control
   */
  public static CHECK_ACCESS = true;

  public static denyLoginAccessByRole: string[] = [];

  /**
   * Method to get the access list
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public static getAccessControlListByRole(): { [key: string]: string[] } {
    let accessControlByRole: Record<string, string[]> = {};


    const COMMON_ROUTES: string[] = [
      //here you can define al common routes regarles the value
      'profile'
    ];

    //you can create more access controls for each value of your business in the same way of  ROLE_ADMIN & ROLE_PATIENT
    accessControlByRole[ROLE_ADMIN.value] = [
      ...COMMON_ROUTES,
      'group',
      'user',
      'clinical',
      'marketing',
      'administrator',
      'list-patient'
      //define all routes access by value admin
    ];
    accessControlByRole[ROLE_PATIENT.value] = [
      ...COMMON_ROUTES,
      'patient'
      //define all routes access by value patient
    ];
    accessControlByRole[ROLE_PROFESSIONAL.value] = [
      ...COMMON_ROUTES,
      'group',
      'user',

      //define all routes access by value admin
    ];

    return accessControlByRole;
  }



}
