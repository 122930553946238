import {AsideDynamicItemConfigType} from '../app/_metronic/layout/components/aside/aside-menu/aside-menu.component';
import {GET_ALL_ROLES_BY_KEY, ROLE_ADMIN} from './roles';

export const ASIDE_CONFIG: AsideDynamicItemConfigType[] = [
  {
    title: 'Dashboard',
    route: '/dashboard',
    pageTitle: 'Documentación',
    svg: './assets/media/icons/duotune/general/gen025.svg',
    roles:[...GET_ALL_ROLES_BY_KEY('value')]
  },
  {
    header: 'TEXT.ADMINISTRATION',
    roles:[ROLE_ADMIN.value]
  },
  {
    title: 'GROUP_MANAGEMENT.LABEL.SINGULAR',
    route: '/management/group',
    svg: './assets/media/icons/duotune/general/gen051.svg',
    roles:[ROLE_ADMIN.value]
  },
  {
    title: 'USER_MANAGEMENT.LABEL.SINGULAR',
    route: '/management/user',
    svg: './assets/media/icons/duotune/general/gen049.svg',
    roles:[ROLE_ADMIN.value]
  },
]
