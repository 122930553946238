import {TypeName, Traceable} from "./interfaces";

/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export interface IGenericBaseEntity {
  updateFromQuery(_instance: unknown): void
}

/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export abstract class AbstractBaseEntity implements Traceable, TypeName, IGenericBaseEntity {
  id?: number | string;
  creationTime: number;
  updatedTime: number;
  __typename: string;

  public abstract updateFromQuery(_instance: unknown): void
}
