<!-- Search toolbar -->
<!--<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div
    class="btn btn-icon btn-active-light-primary position-relative"
    [ngClass]="toolbarButtonHeightClass"
    data-kt-menu-trigger="click"
    data-kt-menu-attach="parent"
    data-kt-menu-placement="bottom-end"
    data-kt-menu-flip="bottom"
  >
    <span
      [inlineSVG]="'./assets/media/icons/duotune/general/gen021.svg'"
      class="svg-icon"
      [ngClass]="toolbarButtonIconSizeClass"
    ></span>
  </div>
  <app-search-result-inner></app-search-result-inner>
</div>-->

<!-- Activities -->
<!--<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  &lt;!&ndash; begin::Drawer toggle &ndash;&gt;
  <div
    class="btn btn-icon btn-active-light-primary"
    [ngClass]="toolbarButtonHeightClass"
    id="kt_activities_toggle"
  >
    <span
      [inlineSVG]="'./assets/media/icons/duotune/general/gen032.svg'"
      class="svg-icon"
      [ngClass]="toolbarButtonIconSizeClass"
    ></span>
  </div>
  &lt;!&ndash; end::Drawer toggle &ndash;&gt;
</div>-->

<!-- NOTIFICATIONS -->
<!--<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div
    class="btn btn-icon btn-active-light-primary position-relative"
    [ngClass]="toolbarButtonHeightClass"
    data-kt-menu-trigger="click"
    data-kt-menu-attach="parent"
    data-kt-menu-placement="bottom-end"
    data-kt-menu-flip="bottom"
  >
    <span
      [inlineSVG]="'./assets/media/icons/duotune/general/gen022.svg'"
      class="svg-icon"
      [ngClass]="toolbarButtonIconSizeClass"
    ></span>
  </div>
  <app-notifications-inner></app-notifications-inner>
</div>-->

<!-- CHAT -->
<!--<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  &lt;!&ndash; begin::Menu wrapper &ndash;&gt;
  <div
    class="btn btn-icon btn-active-light-primary position-relative"
    [ngClass]="toolbarButtonHeightClass"
    id="kt_drawer_chat_toggle"
  >
    <span
      [inlineSVG]="'./assets/media/icons/duotune/communication/com012.svg'"
      class="svg-icon"
      [ngClass]="toolbarButtonIconSizeClass"
    ></span>
    <span
      class="
        bullet bullet-dot
        bg-success
        h-6px
        w-6px
        position-absolute
        translate-middle
        top-0
        start-50
        animation-blink
      "
    ></span>
  </div>
  &lt;!&ndash; end::Menu wrapper &ndash;&gt;
</div>-->

<!-- Quick links -->
<div class="d-flex align-items-center gap-4 me-5" *ngIf="authService.isAdmin()">

  <!-- <a  (click)="myTabs(1); routeToDashboard()" class="bg-transparent my-text-light">
    <img alt="img" src="assets/media/icons/duotune/general/{{dshboardValue}}">
  </a> -->
  <a  (click)="myTabs(1); routeToMarketing()" class="bg-transparent my-text-light">
    <img alt="img" src="assets/media/eyeneb/{{marketingValue}}">
  </a>
  <a  (click)="myTabs(2); routeToClinical()" class="bg-transparent my-text-light" >
    <img alt="img" src="assets/media/eyeneb/{{clinicalValue}}">
  </a>
</div>
<div class="d-flex align-items-center gap-4 me-5" *ngIf="!authService.isAdmin()">

  <a  (click)="myTabs2(1); routeToDashboard()" class="bg-transparent my-text-light">
    <img alt="img" src="assets/media/icons/duotune/general/{{dshboardValue}}">
  </a>
  <a  (click)="myTabs2(2); routeToPatient()" class="bg-transparent my-text-light">
    <img alt="img" src="assets/media/eyeneb/{{patientValue}}">
  </a>
  <!-- <a  (click)="myTabs(3); routeToClinical()" class="bg-transparent my-text-light" >
    <img alt="img" src="assets/media/eyeneb/{{clinicalValue}}">
  </a> -->
</div>
<!-- begin::User -->
<div
  class="d-flex align-items-center"
  [ngClass]="toolbarButtonMarginClass"
  id="kt_header_user_menu_toggle"
>
  <!-- begin::Toggle -->

  <!-- end::Toggle  -->
  <app-user-inner></app-user-inner>
  <div ngbDropdown class="d-inline-block">
    <button class="btn btn-outline-primary" id="dropdownMenuButton" ngbDropdownToggle>
      <img src="./assets/media/avatars/300-1.jpg" alt="metronic" width="31"/>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
      <button (click)="routeToMyData()" class="H18-500-inter dropdown-item">  <div
        class="cursor-pointer symbol"
        [ngClass]="toolbarUserAvatarHeightClass"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"

      >
        Your profile
      </div></button>
      <button (click)="routeToPatient()" class="dropdown-item H18-500-inter" *ngIf="!authService.isAdmin()">Your patients</button>
      <button (click)="routeToAdministrator()" class="dropdown-item H18-500-inter" *ngIf="authService.isAdmin()">Administrator</button>
      <!-- Agrega más opciones según sea necesario -->
    </div>
  </div>
</div>
<!-- end::User -->

<ng-container *ngIf="headerLeft === 'menu'">
  <div
    class="d-flex align-items-center d-lg-none ms-2 me-n3"
    title="Show header menu"
  >
    <div
      class="
        btn btn-icon btn-active-light-primary
        w-30px
        h-30px
        w-md-40px
        h-md-40px
      "
      id="kt_header_menu_mobile_toggle"
    >
      <span
        [inlineSVG]="'./assets/media/icons/duotune/text/txt001.svg'"
        class="svg-icon svg-icon-1"
      ></span>
    </div>
  </div>
</ng-container>
