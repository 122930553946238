import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InlineSVGModule} from 'ng-inline-svg-2';
import {RouterModule, Routes} from '@angular/router';
import {
  NgbDropdownModule,
  NgbProgressbarModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {TranslationModule} from '../../modules/i18n';
import {LayoutComponent} from './layout.component';
import {Routing} from '../../pages/routing';
import {AsideComponent} from './components/aside/aside.component';
import {HeaderComponent} from './components/header/header.component';
import {ContentComponent} from './components/content/content.component';
import {FooterComponent} from './components/footer/footer.component';
import {ScriptsInitComponent} from './components/scripts-init/scripts-init.component';
import {AsideMenuComponent} from './components/aside/aside-menu/aside-menu.component';
import {TopbarComponent} from './components/topbar/topbar.component';
import {PageTitleComponent} from './components/header/page-title/page-title.component';
import {HeaderMenuComponent} from './components/header/header-menu/header-menu.component';
import {UserManagementModule} from "../../modules/user-management/user-management.module";
import {SharedModule} from "../../modules/shared/shared.module";
import {UserInnerComponent} from "./components/_partials/user-inner/user-inner.component";
import { SpinnerModule } from "../../modules/shared/components/spinner/spinner.module";

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: Routing,
  },
];

@NgModule({
    declarations: [
        LayoutComponent,
        AsideComponent,
        HeaderComponent,
        ContentComponent,
        FooterComponent,
        ScriptsInitComponent,
        AsideMenuComponent,
        TopbarComponent,
        PageTitleComponent,
        HeaderMenuComponent,
        UserInnerComponent,
    ],
    exports: [RouterModule],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        TranslationModule,
        InlineSVGModule,
        NgbDropdownModule,
        NgbProgressbarModule,
        UserManagementModule,
        NgbTooltipModule,
        TranslateModule,
        SharedModule,
        SpinnerModule
    ]
})
export class LayoutModule {
}
