import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPaginationInfo, IPaginator } from './models/paginator.model';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@Component({
  selector: 'app-my-paginator',
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './my-paginator.component.html',
  styleUrls: ['./my-paginator.component.scss']
})
export class MyPaginatorComponent implements OnInit {

  @Input() paginatorState!: IPaginator;
  @Output() paginateChange: EventEmitter<string> = new EventEmitter();
  opcionSeleccionado:String="0"
  constructor() { }
  ngOnInit(): void {
    //console.log("Este es el Paginator: ", this.paginatorState);

  }
  nextPage(): void {
    if (this.hasNextFunc()) {
      this.setPage(this.paginatorState.page + 1);
    }
  }
  prevPage(): void {
    if (this.hasPrevFunc()) {
      this.setPage(this.paginatorState.page - 1);
    }
  }
  setPage(page: number) {
    this.paginatorState.page = page;
    this.paginateChange.emit("load");
  }
  hasNextFunc() {
    return this.paginatorState.lastPage > this.paginatorState.page;
  }
  hasPrevFunc() {
    return this.paginatorState.page > 1;
  }
  updatePaginationInfo(pagination: IPaginationInfo) {
    this.paginatorState.itemsPerPage = +pagination.itemsPerPage;
    this.paginatorState.lastPage = pagination.lastPage;
    this.paginatorState.totalCount = pagination.totalCount;
    if(this.paginatorState.lastPage<this.paginatorState.page){
      this.setPage(1);
    }
    //console.log(this.paginatorState.totalCount);
  }
  changeItemsPerPage(value: any) {
    this.paginatorState.itemsPerPage = + parseInt(value);
    this.paginateChange.emit("load");
  }

}
