import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-no-data-template',
  template: `
        <div class="w-100 d-flex flex-column justify-content-center align-items-center text-center">
          <img [style.width]="imageWidth" [src]="image" [ngClass]="imageClass" alt="no_data">
          <span class="fw-bold text-muted fs-4 mt-5" [innerHTML]="message"></span>
        </div>
  `,
  styles: [`
  `]
})
/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export class NoDataTemplateComponent implements OnInit {

  @Input() message: string = 'Lo sentimos no hay información disponible'

  @Input() showImage: boolean = true
  @Input() image: string = 'assets/media/svg/no_data.svg'
  @Input() imageWidth: number | string = '250px';
  @Input() imageClass: string | string[];

  constructor() { }

  ngOnInit(): void {
  }

}
