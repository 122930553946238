import {PAGINATOR_LOCALE_ES} from "../../shared/modules/crud-table/components/paginator/i18n/locale";
import {USER_MANAGEMENT_LOCALE_ES} from "../../user-management/i18n/locale";
import {I18N_PARTIALS_ES} from "../partials/es";
import {GROUP_MANAGEMENT_LOCALE_ES} from "../../group-management/i18n/locale";
import {ILangVocab} from "../../../../_config/languages";

export const locale: ILangVocab = {
  lang: 'es',
  data: {
    TRANSLATOR: {
      SELECT: 'Elige tu idioma',
    },
    MENU: {
      DASHBOARD: 'Tablero'
    },
    TEXT: {
      MANAGEMENT: 'Gestión',
      ADMINISTRATION: 'Administración',
      SELECT: 'Seleccionar',
      ONLINE_TITLE: 'Conexión restablecida',
      ONLINE_MESSAGE: 'Vuelve a estar en línea.',
      OFFLINE_TITLE: '¡Conexión perdida!',
      OFFLINE_MESSAGE: 'No estas conectado a internet.',
      NO_ITEMS_FOUND: 'No se encontraron elementos',
    },
    ROLE: {
      ADMIN: 'Administrador',
      PATIENT: 'Paciente'
    },
    GENDER: {
      MALE: 'Masculino',
      FEMALE: 'Femenino',
      INTERSEXUAL: 'Intersexual',
      OTHER: 'Otro',
    },
    //TODO: Adicionar los translations del modulo auth
    ...I18N_PARTIALS_ES,
    ...PAGINATOR_LOCALE_ES,
    ...USER_MANAGEMENT_LOCALE_ES,
    ...GROUP_MANAGEMENT_LOCALE_ES,
  }
};
