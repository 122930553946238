/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export interface IRole {
  value: string,
  name: string
}

export const ROLE_PATIENT: IRole = {name: 'ROLE.PATIENT', value: 'ROLE_PATIENT'};
export const ROLE_ADMIN: IRole = {name: 'ROLE.ADMIN', value: 'ROLE_ADMIN'};
export const ROLE_PROFESSIONAL: IRole = {name: 'ROLE.PROFESSIONAL', value: 'ROLE_PROFESSIONAL'};

export const AVAILABLE_ROLES: IRole[] = [
  ROLE_PATIENT,
  ROLE_ADMIN,
  ROLE_PROFESSIONAL,
]

/**
 * Returns an array of all roles by the specified key.
 * @param {string} key - The key to use for retrieving roles. Can be either 'value' or 'name'.
 * @returns {Array} An array of all roles by the specified key.
 *
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export function GET_ALL_ROLES_BY_KEY(key: 'value' | 'name' = 'value') {
  return AVAILABLE_ROLES.map(role => role[key]);
}
