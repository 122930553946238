import {BaseUser} from "../../shared/model/base-user";
import {ApiResourceName} from "../../shared/modules/crud-table/abstract/generic-form/decorator-reflect-metadata";

export type UserExtended =
  | User
  | Patient

@ApiResourceName('User')
export class User extends BaseUser {
  gender: string
  declare mobile: string
  dni?: string = ''
  declare id: string
  birthdate: number | null = null

  updateFromQuery(_instance: unknown) {
    super.updateFromQuery(_instance);

    const user = _instance as any;

    this.birthdate = user.birthdate
    this.gender = user.gender
    this.dni = user.dni || ''
    this.roles = user.roles || ['ROLE_USER']
  }

  getFullName(): string {
    return `${this.name} ${this.lastname}`
  }
}

@ApiResourceName('Patient')
export class Patient extends User {
}
