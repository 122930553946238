import {Component} from '@angular/core';
import {UserService} from "../../services/user.service";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SweetAlertService} from "../../../shared/services/sweet-alert.service";
import {FormComponent} from "../form/form.component";
import {User} from "../../model/user";
import {ApolloHelperService} from "../../../shared/services/apollo-helper.service";
import {IGenericTableHeader} from "../../../shared/modules/crud-table/components/generic-table/generic-table.component";
import {BehaviorSubject, noop} from "rxjs";
import {AVAILABLE_GENDERS} from "../../../../../_config/genders";
import {AVAILABLE_ROLES} from "../../../../../_config/roles";
import {GenericTableFilter} from "../../../shared/modules/crud-table/abstract/generic-list/generic-list";


@Component({
  selector: 'app-user-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent {
  //filters
  enabledFilter: boolean | null = null;
  genderFilter: string = '';
  roleFilter: string = '';
  tableFilters: BehaviorSubject<GenericTableFilter> = new BehaviorSubject<GenericTableFilter>(null);

  genders = AVAILABLE_GENDERS
  roles = AVAILABLE_ROLES

  headers: IGenericTableHeader[] = [
    {
      label: 'Id',
      sort: {
        column: 'id',
      }
    },
    {
      label: 'USER_MANAGEMENT.FIELD.NAME',
      sort: {
        column: 'name',
      }
    },
    {
      label: 'USER_MANAGEMENT.FIELD.CENTER',
      sort: {
        column: 'username',
      }
    },
    {
      label: 'USER_MANAGEMENT.FIELD.PATIENT',
      sort: {
        column: 'email',
      }
    },
    {
      label: 'USER_MANAGEMENT.FIELD.GROUPS',
      sort: {
        column: 'gender',
        enabled: false
      }
    },
    {
      label: 'USER_MANAGEMENT.FIELD.ENABLED',
      sort: {
        column: 'enabled',
      }
    },
    {
      label: 'ACTION.PLURAL',
      class: 'text-end',
    },
  ];

  constructor(
    public userService: UserService,
    private modalService: NgbModal,
    private alertService: SweetAlertService,
  ) {
    this.userService.tableSearchableFields = [{orWhereFilter: ['name', 'lastname', 'username', 'email']}]
  }


  /**
   * Method to remove a user from the list
   *
   * @param id
   */
  async delete(id: string): Promise<void> {
    let confirm = await this.alertService.confirm().catch(reason => reason);

    if (confirm.isConfirmed) {
      let deleteResponse = await this.userService.deleteInstance(id).catch(reason => reason);
      if (deleteResponse.hasOwnProperty('errors')) {
        const error = ApolloHelperService.getProcessedErrorFromResponse(deleteResponse);
        if (error) {
          await this.alertService.error({
            title: error.category,
            text: error.message,
          });
        }

        return;
      }

      this.alertService.success().then();
      await this.userService.refreshApolloQuery();
    }
  }

  async form(id?: string) {
    const modalRef = this.modalService.open(FormComponent, {size: 'lg'});
    modalRef.componentInstance.genericEntityId = id;
    modalRef.result.then(async () => {
      await this.userService.refreshApolloQuery();
    })
      .catch(noop);
  }

  public async toggleUser(user: User, event: Event) {
    let switchInput = (event.target as HTMLInputElement);
    const currentValue = switchInput.checked;
    await this.userService
      .toggleState(user.id, currentValue)
      .catch(async reason => {
        console.error('toggleUser', reason)
        switchInput.checked = !currentValue;
        await this.alertService.error()
      })
    ;
  }

  resetFilter() {
    this.enabledFilter = null;
    this.genderFilter = '';
    this.roleFilter = '';
    this.tableFilters.next(null);
  }

  applyFilter() {
    let filters: Record<any, any> = {};

    if (null !== this.enabledFilter) {
      filters.enabled = this.enabledFilter;
    }

    if ('' !== this.genderFilter) {
      filters.gender = this.genderFilter;
    }

    if ('' !== this.roleFilter) {
      filters.groups_roles = this.roleFilter;
    }


    this.tableFilters.next(filters);

  }
}
