import {inject, Injectable, Pipe, PipeTransform} from '@angular/core';
import {AVAILABLE_ROLES} from "../../../../_config/roles";
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'authRoleConverter',
  standalone: true
})
@Injectable({
  providedIn: 'root',
})
/**
 * AuthRoleConverterPipe is used to transform a string or array of strings representing roles into an array of human-readable value names.
 *
 * The transform method takes in a value parameter that can be a string, an array of strings. The method then maps over
 * the roles array and calls the private transformRolesName method for each value.
 *
 * In summary, this class is used to convert value names from internal names to human-readable names.
 */
export class AuthRoleConverterPipe implements PipeTransform {

  private translator = inject(TranslateService);

  transform(value: string | string[] | any[], joinValues: boolean = false, delimiter: string = ', '): string | string[] {

    if (!Array.isArray(value)) {
      return this.transformRolesName(value)
    }

    value = value.map((role: string) => this.transformRolesName(role));
    return joinValues ? value.join(delimiter) : value;
  }

  private transformRolesName(value: string): string {
    let roleExist = AVAILABLE_ROLES.filter(role => role.value === value)

    if (roleExist.length > 0) {
      value = roleExist[0].name;
    }

    return this.translator.instant(value);
  }
}
