<div class="d-flex flex-column flex-root ">
  <div class="d-flex">

    <div class="d-flex flex-column flex-row-fluid" id="kt_wrapper">
      <app-header
        class="w-100 align-items-stretch"

      ></app-header>
      <div class="content d-flex flex-column flex-column-fluid">
        <ng-container >
        </ng-container>

        <div class="post d-flex flex-column-fluid" id="kt_post">
          <app-content
          ></app-content>
        </div>
      </div>

    </div>
  </div>
</div>

<!--<app-scripts-init></app-scripts-init>-->
<!--<app-scroll-top></app-scroll-top>-->

<!-- begin:: Drawers -->
<!--<app-activity-drawer></app-activity-drawer>
<app-messenger-drawer></app-messenger-drawer>-->
<!-- end:: Drawers -->

<!-- end:: Engage -->
<!--<app-engages></app-engages>-->
<!-- end:: Engage -->

<!-- begin:: Modals -->
<!--<app-main-modal></app-main-modal>
<app-invite-users-modal></app-invite-users-modal>
<app-upgrade-plan-modal></app-upgrade-plan-modal>-->
<!-- end:: Modals -->
