import {Injectable} from '@angular/core';
import {GenericCRUDTableService, TWatchQueryOptionsExtended} from "../../shared/modules/crud-table";
import {UserExtended} from "../model/user";
import {QUERIES_USER_MANAGEMENT} from "../_graphql/_queries";
import {ApolloMutationResult, MUTATION_ACTION} from "../../shared/services/apollo-helper.service";
import {BehaviorSubject} from "rxjs";
import {HelperTools} from "../../shared/services/helperTools";

export enum UserInstanceEnum {
  user = 'User',
  patient = 'Patient',
  userEndpoint = 'users',
  patientEndpoint = 'patients',
}


@Injectable({
  providedIn: 'root'
})
export class UserService extends GenericCRUDTableService<UserExtended> {
  configureQueryOptions(): TWatchQueryOptionsExtended {
    return {
      query: QUERIES_USER_MANAGEMENT.user.findAll,
    };
  }

  /**
   * Delete a user from the server
   */
  deleteInstance(id: string): Promise<ApolloMutationResult> {
    //we return a promise to remove
    return this.mutate({
      action: MUTATION_ACTION.delete,
      instance: this.inheritUserInstanceFromID(id),
      enabledSecurity: true,
      data: {
        id: id
      }
    })
  }

  toggleState(id: string, state: boolean) {
    return this.mutate({
        action: MUTATION_ACTION.update,
        instance: this.inheritUserInstanceFromID(id),
        data: {id: id, enabled: state},
        isLoading$: this._isLoading$,
        enabledSecurity: true
      }
    )
  }

  inheritUserInstanceFromID(iri: string): UserInstanceEnum.user | UserInstanceEnum.patient {

    //get endpoint based on iri
    let iriEndpoint = HelperTools.getIriEndpoint(iri);

    if (null === iriEndpoint) {
      throw new Error(`No se pudo determinar la instancia de este IRI "${iri}"`)
    }

    switch (iriEndpoint) {
      case UserInstanceEnum.patientEndpoint:
        return UserInstanceEnum.patient;
      default:
        return UserInstanceEnum.user;
    }
  }


  fetchAllGroupsTypes(isLoadingObserver?: BehaviorSubject<boolean>): Promise<any> {
    return this.apolloHelper.fetch({
      query: QUERIES_USER_MANAGEMENT.group.findAll,
      isCollection: true,
      variables: {active: true},
      isLoading$: isLoadingObserver
    })
  }

}
