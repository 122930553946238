import {inject, Injectable} from '@angular/core';
import {GenericTableService} from './table.service';
import {BehaviorSubject} from 'rxjs';
import {
  ApolloHelperService,
  ApolloMutation,
  ApolloMutationResult,
  ApolloQuery,
  MUTATION_ACTION,
  MutationAction
} from '../../../services/apollo-helper.service';
import {DecoratorReflectMetadata} from '../abstract/generic-form/decorator-reflect-metadata';

/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export interface ICRUDTableService {
  fetchElementById(
    {
      query,
      id,
      isLoading$,
      freezeResults = true
    }: {
      query: ApolloQuery,
      id: string,
      isLoading$?: BehaviorSubject<boolean>,
      freezeResults?: boolean
    }
  ): Promise<any>;

  mutate({
           data,
           enabledSecurity,
           isLoading$,
         }: {
    data: any,
    enabledSecurity?: boolean,
    isLoading$?: BehaviorSubject<boolean>,
    [key: string]: any,

  }): Promise<ApolloMutationResult>
}

@Injectable({
  providedIn: 'root'
})
/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export abstract class GenericCRUDTableService<T> extends GenericTableService<T> implements ICRUDTableService {

  //Services DI
  protected readonly apolloHelper: ApolloHelperService = inject(ApolloHelperService);

  async fetchElementById(
    {
      query,
      id,
      isLoading$,
      freezeResults = true
    }: {
      /**
       * Graphql query
       */
      query: ApolloQuery,
      /**
       * ID for searching an exact object
       */
      id: string,
      /**
       * Its process the response object for you and return de collection or object directly, by default is true, in case
       * you want raw response set to false.
       */
      processResult?: boolean,
      /**
       * This options allows you to apply to the response object or collection writable or readonly properties, default value true.

       * When is true you cant modified responses values directly, you will need to clone or create a new object based on the response and then use it
       * to modify the required value
       *
       * If is false, you will be able to modified directly any property in the response object, esta opcion consume mas tiempo y recurso
       *
       */
      freezeResults?: boolean,
      /**
       * Alternative you can pass a boolean observable to the query, and automatically receive the information if query is loading or not
       * it's quite handy when you want to show loading state for a query
       */
      isLoading$?: BehaviorSubject<boolean>
    }
  ): Promise<T> {
    return await this.apolloHelper.fetchById({
      query,
      id,
      isLoading$,
      freezeResults
    });
  }

  /**
   * It allows mutation to be carried out in a generic way, since we only have to pass
   * the instance that we want to mutate and the action, with this the mutation
   * is automatically generated for us.
   *
   * @param action
   * @param instance
   * @param data
   * @param isLoading$
   * @param enabledSecurity
   * @param mutationSubQuery
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public mutate({action, instance, data, isLoading$, enabledSecurity, mutationSubQueryFields, processResult = true}: {
    action: MutationAction,
    instance: any,
    data: any,
    isLoading$?: BehaviorSubject<boolean>,
    enabledSecurity?: boolean,
    mutationSubQueryFields?: string,
    processResult?: boolean
  }): Promise<ApolloMutationResult>

  /**
   * It allows mutations to be carried out in the traditional way in which we pass
   * the mutation that we want to apply
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public mutate({mutation, data, isLoading$, enabledSecurity, processResult = true}: {
    mutation: ApolloMutation,
    data: any,
    isLoading$?: BehaviorSubject<boolean>,
    enabledSecurity?: boolean
    useInputKeyVar?: boolean
    processResult?: boolean
  }): Promise<ApolloMutationResult>;

  /**
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   * @param options
   */
  public mutate(options: any): Promise<ApolloMutationResult> {

    const data = options.data;
    const enabledSecurity = options.enabledSecurity || true;
    const isLoading$ = options.isLoading$;
    const processResult = options.processResult;

    // if instance options is defined, then is a generic mutation
    if (options.hasOwnProperty('instance')) {

      let instance = options.instance;
      let mutationSubQueryFields = options.mutationSubQueryFields;
      const action: MutationAction = options.action; //get mutation action

      //check if submitted action is a valid one
      if (!MUTATION_ACTION.isAllowedValue(action)) {
        const allowedActions = MUTATION_ACTION.getAllValues().join(', ');
        throw new Error(`CRUDTableService::mutate - Action (${action}) is not valid.
        Allowed actions are ${allowedActions}.`);
      }

      const INSTANCE_ERROR = 'CRUDTableService::mutate - The instance parameter is not valid, the allowed types are string or clases.';
      if (undefined === instance) {
        throw new Error(INSTANCE_ERROR)
      }

      //check if instance is class type
      if ('string' !== typeof instance) {
        //check if property name in class has value
        if (undefined === instance.name && undefined === instance.constructor) {
          throw new Error(INSTANCE_ERROR)
        }

        const instanceType = typeof instance;

        //get original class name from metadata
        //is mandatory use @ApiResourceName annotation on any class you want to mutate generically,
        //this annotation guarantee obtain always the real class name
        //after minification process.

        switch (instanceType) {
          case 'function':
            instance = DecoratorReflectMetadata.getClassName(instance)
            break;
          case 'object':
            instance = DecoratorReflectMetadata.getInstanceName(instance)
            break
          default:
            throw new Error(INSTANCE_ERROR)
        }

        if (undefined === instance) {
          throw new Error(`CRUDTableService::mutate - Invalid name value (${instance}), apparently you have not applied the @ClassName decorator to the provided class.`)
        }

      }

      //validate that the value obtained is a string and that at least it is not empty
      if ('string' === typeof instance && instance.trim().length === 0) {
        throw new Error('CRUDTableService::mutate - The instance name is not valid.')
      }

      return this.apolloHelper.mutate({
        mutation: ApolloHelperService.createGenericMutation(instance, action, mutationSubQueryFields),
        variables: {input: data},
        enabledSecurity,
        isLoading$,
        processResult
      });

    }

    //at this point is a normal mutation
    const mutation: ApolloMutation = options.mutation;
    const useInputKeyVar: boolean = options.useInputKeyVar || true;

    return this.apolloHelper.mutate({
      mutation,
      variables: useInputKeyVar ? {input: data} : data,
      enabledSecurity,
      isLoading$,
      processResult
    });
  }

}
