<!-- Dashboard -->
<ng-template #menuItem let-item="item">
  <div class="menu-item" isGranted [grantedRoles]="item.roles" [ifNotGranted]="item.isNotGrantedAction">
    <a
      class="menu-link without-sub"
      [routerLink]="item.route"
      routerLinkActive="active">
      <span class="menu-icon" *ngIf="item.icon || item.svg">
        <span
          *ngIf="item.svg"
          [inlineSVG]="item.svg"
          class="svg-icon svg-icon-2"
        ></span>
        <i
          *ngIf="item.icon"
          class="ms-1"
          [ngClass]="item.icon"
        ></i>
      </span>
      <span [attr.data-page-title]="item.pageTitle | translate" class="menu-title" [translate]="item.title"></span
      ></a>
  </div>
</ng-template>

<ng-template #compositeMenu let-item="item">

  <div
    class="menu-item menu-accordion"
    [ngClass]="{'here show': isSubItemActivated(item.submenu)}"
    isGranted [grantedRoles]="item.roles" [ifNotGranted]="item.isNotGrantedAction"
    data-kt-menu-trigger="click"
    routerLinkActive="here show">
  <span class="menu-link">
    <span class="menu-icon" *ngIf="item.icon || item.svg">
        <span
          *ngIf="item.svg"
          [inlineSVG]="item.svg"
          class="svg-icon svg-icon-2"
        ></span>
        <i
          *ngIf="item.icon"
          class="ms-1"
          [ngClass]="item.icon"
        ></i>
      </span>
    <span class="menu-title" [translate]="item.title"></span>
    <span class="menu-arrow"></span>
  </span>
    <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
      <ng-container
        *ngFor="let subMenu of item.submenu"
        [ngTemplateOutlet]="menuItem"
        [ngTemplateOutletContext]="{item: subMenu}"
      ></ng-container>
    </div>
  </div>


</ng-template>

<ng-template #menuSection let-item="item">
  <div class="menu-item" isGranted [grantedRoles]="item.roles">
    <div class="menu-content pb-2">
      <span
        class="menu-section text-muted text-uppercase fs-8 ls-1"
        [ngClass]="item.class"
        [ngStyle]="item.styles"
        [translate]="item.section"></span>
    </div>
  </div>
</ng-template>

<ng-container *ngFor="let menu of asideConfig">

  <ng-container
    *ngIf="undefined !== menu.header"
    [ngTemplateOutlet]="menuSection"
    [ngTemplateOutletContext]="{item: {section: menu.header, roles: undefined === menu.headerRoles ? menu.roles :  menu.headerRoles, styles: menu.headerStyles, class: menu.headerClass}}"
  ></ng-container>

  <ng-container
    *ngIf="undefined !== menu.title && undefined !== menu.submenu"
    [ngTemplateOutlet]="compositeMenu"
    [ngTemplateOutletContext]="{item: menu}"
  ></ng-container>

  <ng-container
    *ngIf="undefined !== menu.title && undefined === menu.submenu"
    [ngTemplateOutlet]="menuItem"
    [ngTemplateOutletContext]="{item: menu}"
  ></ng-container>


</ng-container>



<!--<div class="menu-item">-->
<!--  <div class="menu-content pt-8 pb-2">-->
<!--    <span class="menu-section text-muted text-uppercase fs-8 ls-1" translate="TEXT.ADMINISTRATION"></span>-->
<!--  </div>-->
<!--</div>-->

<!--<div class="menu-item">-->
<!--  <a-->
<!--    class="menu-link without-sub"-->
<!--    routerLink="/management/user"-->
<!--    routerLinkActive="active"-->
<!--    ><span class="menu-icon">-->
<!--      <span-->
<!--        [inlineSVG]="'./assets/media/icons/duotune/general/gen049.svg'"-->
<!--        class="svg-icon svg-icon-2"-->
<!--      ></span> </span-->
<!--    ><span class="menu-title" translate="USER_MANAGEMENT.LABEL.SINGULAR"></span></a-->
<!--  >-->
<!--</div>-->
<!--<div class="menu-item">-->
<!--  <a-->
<!--    class="menu-link without-sub"-->
<!--    routerLink="/management/groups"-->
<!--    routerLinkActive="active"-->
<!--  ><span class="menu-icon">-->
<!--      <span-->
<!--        [inlineSVG]="'./assets/media/icons/duotune/general/gen051.svg'"-->
<!--        class="svg-icon svg-icon-2"-->
<!--      ></span> </span-->
<!--  ><span class="menu-title" translate="GROUP_MANAGEMENT.LABEL.SINGULAR"></span></a-->
<!--  >-->
<!--</div>-->





<!-- Separator -->

<!-- Pages -->
<!--<div
  class="menu-item menu-accordion"
  data-kt-menu-trigger="click"
  routerLinkActive="here show"
>
  <span class="menu-link"
    ><span class="menu-icon"
      ><span
        class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/general/gen022.svg'"
      ></span></span
    ><span class="menu-title">Pages</span><span class="menu-arrow"></span
  ></span>
  <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
    <div
      class="menu-item menu-accordion"
      routerLinkActive="here show"
      data-kt-menu-trigger="click"
    >
      <span class="menu-link"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title" data-link="/crafted/pages/my-data"
          >Profile</span
        ><span class="menu-arrow"></span
      ></span>
      <div
        class="menu-sub menu-sub-accordion"
        routerLinkActive="menu-active-bg"
      >
        <div class="menu-item">
          <a
            class="menu-link without-sub"
            routerLinkActive="active"
            routerLink="/crafted/pages/my-data/overview"
            ><span class="menu-bullet"
              ><span class="bullet bullet-dot"></span></span
            ><span class="menu-title">Overview</span></a
          >
        </div>
        <div class="menu-item">
          <a
            class="menu-link without-sub"
            routerLinkActive="active"
            routerLink="/crafted/pages/my-data/projects"
            ><span class="menu-bullet"
              ><span class="bullet bullet-dot"></span></span
            ><span class="menu-title">Projects</span></a
          >
        </div>
        <div class="menu-item">
          <a
            class="menu-link without-sub"
            routerLinkActive="active"
            routerLink="/crafted/pages/my-data/campaigns"
            ><span class="menu-bullet"
              ><span class="bullet bullet-dot"></span></span
            ><span class="menu-title">Campaigns</span></a
          >
        </div>
        <div class="menu-item">
          <a
            class="menu-link without-sub"
            routerLinkActive="active"
            routerLink="/crafted/pages/my-data/documents"
            ><span class="menu-bullet"
              ><span class="bullet bullet-dot"></span></span
            ><span class="menu-title">Documents</span></a
          >
        </div>
        <div class="menu-item">
          <a
            class="menu-link without-sub"
            routerLinkActive="active"
            routerLink="/crafted/pages/my-data/connections"
            ><span class="menu-bullet"
              ><span class="bullet bullet-dot"></span></span
            ><span class="menu-title">Connections</span></a
          >
        </div>
      </div>
    </div>
    <div
      class="menu-item menu-accordion"
      routerLinkActive="here show"
      data-kt-menu-trigger="click"
    >
      <span class="menu-link"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title" data-link="/crafted/pages/wizards"
          >Wizards</span
        ><span class="menu-arrow"></span
      ></span>
      <div
        class="menu-sub menu-sub-accordion"
        routerLinkActive="menu-active-bg"
      >
        <div class="menu-item">
          <a
            class="menu-link without-sub"
            routerLinkActive="active"
            routerLink="/crafted/pages/wizards/horizontal"
            ><span class="menu-bullet"
              ><span class="bullet bullet-dot"></span></span
            ><span class="menu-title">Horizontal</span></a
          >
        </div>
        <div class="menu-item">
          <a
            class="menu-link without-sub"
            routerLinkActive="active"
            routerLink="/crafted/pages/wizards/vertical"
            ><span class="menu-bullet"
              ><span class="bullet bullet-dot"></span></span
            ><span class="menu-title">Vertical</span></a
          >
        </div>
      </div>
    </div>
  </div>
</div>-->

<!-- Accounts -->
<!--<div
  class="menu-item menu-accordion"
  data-kt-menu-trigger="click"
  routerLinkActive="here show"
>
  <span class="menu-link"
    ><span class="menu-icon"
      ><span
        class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/communication/com006.svg'"
      ></span></span
    ><span class="menu-title" data-link="/crafted/account">Accounts</span
    ><span class="menu-arrow"></span
  ></span>
  <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/crafted/account/overview"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Overview</span></a
      >
    </div>
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/crafted/account/settings"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Settings</span></a
      >
    </div>
  </div>
</div>-->

<!-- Errors -->
<!--<div
  class="menu-item menu-accordion"
  data-kt-menu-trigger="click"
  routerLinkActive="here show"
>
  <span class="menu-link"
    ><span class="menu-icon"
      ><span
        class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/general/gen040.svg'"
      ></span></span
    ><span class="menu-title" data-link="/error">Errors</span
    ><span class="menu-arrow"></span
  ></span>
  <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLink="/error/404"
        routerLinkActive="active"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Error 404</span></a
      >
    </div>
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLink="/error/500"
        routerLinkActive="active"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Error 500</span></a
      >
    </div>
  </div>
</div>-->

<!-- Widgets -->
<!--<div
  class="menu-item menu-accordion"
  data-kt-menu-trigger="click"
  routerLinkActive="here show"
>
  <span class="menu-link"
    ><span class="menu-icon"
      ><span
        class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/general/gen025.svg'"
      ></span></span
    ><span class="menu-title" data-link="/crafted/widgets">Widgets</span
    ><span class="menu-arrow"></span
  ></span>
  <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/crafted/widgets/lists"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Lists</span></a
      >
    </div>
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/crafted/widgets/statistics"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Statistics</span></a
      >
    </div>
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/crafted/widgets/charts"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Charts</span></a
      >
    </div>
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/crafted/widgets/mixed"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Mixed</span></a
      >
    </div>
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/crafted/widgets/tables"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Tables</span></a
      >
    </div>
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/crafted/widgets/feeds"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Feeds</span></a
      >
    </div>
  </div>
</div>-->

<!-- Separator -->
<!--<div class="menu-item">
  <div class="menu-content pt-8 pb-2">
    <span class="menu-section text-muted text-uppercase fs-8 ls-1">Apps</span>
  </div>
</div>-->

<!-- Chat -->
<!--<div
  class="menu-item menu-accordion"
  data-kt-menu-trigger="click"
  routerLinkActive="here show"
>
  <span class="menu-link"
    ><span class="menu-icon"
      ><span
        class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/communication/com012.svg'"
      ></span></span
    ><span class="menu-title" data-link="/apps/chat">Chat</span
    ><span class="menu-arrow"></span
  ></span>
  <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/apps/chat/private-chat"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Private Chat</span></a
      >
    </div>
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/apps/chat/group-chat"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Group Chart</span></a
      >
    </div>
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/apps/chat/drawer-chat"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Drawer Chart</span></a
      >
    </div>
  </div>
</div>-->

<!-- Separator -->
<!--<div class="menu-item">
  <div class="menu-content">
    <div class="separator mx-1 my-4"></div>
  </div>
</div>-->

<!-- Changelog  -->
<!--<div class="menu-item">
  <a target="_blank" class="menu-link" [href]="appPreviewChangelogUrl">
    <span class="menu-icon">
      <span
        class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/general/gen005.svg'"
      ></span>
    </span>
    <span class="menu-title">Changelog {{ appAngularVersion }}</span>
  </a>
</div>-->
