import { inject } from '@angular/core';
import {Routes} from '@angular/router';
import { AuthService } from '../modules/auth';
import {AccessGuardCanMatchFn} from '../modules/auth/services/access.guard';
import { UserService } from './patient/services/user.service';

//const userService = inject(AuthService);

let homepage="marketing";
/* if(userService.isAdmin()){
  homepage = "/marketing"
}else homepage="/dashboard" */

const Routing: Routes = [
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'data-user',
    loadChildren: () =>
      import('./data-user/data-user.module').then((m) => m.DataUserModule),
  },
  {
    path: 'patient',
    loadChildren: () =>
      import('./patient/patient.module').then((m) => m.PatientModule),
  },
  {
    path: 'patient-details/:username',
    loadChildren: () =>
      import('./patient-details/patient-details.module').then((m) => m.PatientDetailsModule),
  },
  {
    path: 'professional-details',
    loadChildren: () =>
      import('./professional-details/professional-details.module').then((m) => m.ProfessionalDetailsModule),
  },
  {
    path: 'management',
    loadChildren: () =>
      import('../modules/user-management/user-management.module').then((m) => m.UserManagementModule),
  },
   {
    path: 'management',
    loadChildren: () =>
      import('../modules/group-management/group-management.module').then((m) => m.GroupManagementModule),
  },
  { path: 'clinical',
    canMatch: [AccessGuardCanMatchFn],
    loadChildren: () =>
    import('../modules/clinical/clinical.module').then(m => m.ClinicalModule)
  },
  { path: 'marketing',
    canMatch: [AccessGuardCanMatchFn],
    loadChildren: () =>
    import('../modules/marketing/marketing.module').then(m => m.MarketingModule)
  },
  { path: 'admin',
    loadChildren: () =>
    import('../modules/administrator/administrator.module').then(m => m.AdministratorModule)
  },
  {
    path: '',
    canMatch: [AccessGuardCanMatchFn],
    redirectTo: "marketing",
    pathMatch: 'full',
  },
  {
    path: '',
    redirectTo: "dashboard",
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
];

export { Routing };
