import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../core/layout.service';
import {Router} from "@angular/router";
import {AuthService} from "../../../../modules/auth";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {

  toolbarButtonMarginClass = 'ms-1 ms-lg-3';
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px';
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';
  toolbarButtonIconSizeClass = 'svg-icon-1';
  headerLeft: string = 'menu';

  marketingValue: string = "tab-marqueting.svg";
  clinicalValue: string = "tab-clinical-i.svg";
  dshboardValue: string = "gen001-i.svg";
  patientValue: string = "tab-patients-i.svg";
  constructor(
    private layout: LayoutService,
    private router: Router,
    public authService: AuthService
  ) {}

  async ngOnInit() {
    this.headerLeft = this.layout.getProp('header.left') as string;
  }

  myTabs( value: number) {
    if (value==1){
      this.marketingValue = "tab-marqueting.svg";
      this.clinicalValue = "tab-clinical-i.svg";
      /* this.dshboardValue = "gen001-i.svg"; */
     }
     if (value==2){
     this.marketingValue = "tab-marqueting-i.svg";
     this.clinicalValue = "tab-clinical.svg";
     this.dshboardValue = "gen001.svg";
    }
      /* if (value==3){
       this.marketingValue = "tab-marqueting-i.svg";
       this.clinicalValue = "tab-clinical.svg";
       this.dshboardValue = "gen001.svg";
    } */
  }

  myTabs2( value: number) {
    if (value==1){
      this.patientValue = "tab-patients-i.svg";
      this.dshboardValue = "gen001-i.svg";
     }
     if (value==2){
     this.patientValue = "tab-patients.svg";
     this.dshboardValue = "gen001.svg";
    }
  }

  async routeToMyData() {
    await this.router.navigate(['data-user']);
  }
  async routeToClinical() {
    await this.router.navigate(['clinical']);
  }
  async routeToMarketing() {
    await this.router.navigate(['marketing']);
  }
  async routeToPatient() {
    await this.router.navigate(['patient']);
  }
  async routeToAdministrator() {
    await this.router.navigate(['admin/administrator']);
  }
  async routeToDashboard() {
    await this.router.navigate(['dashboard']);
  }

}
