import { GenericGroupingState } from './grouping.model';
import { GenericPaginatorState } from './paginator.model';
import { GenericSortState } from './sort.model';
import {Observable} from "rxjs";

export interface IGenericTableService {
  get items$(): Observable<any[]>
  get grouping(): GenericGroupingState;
  get paginator(): GenericPaginatorState;
  get sorting(): GenericSortState;
  get isLoading$(): Observable<boolean>;

  fetchApollo(): void;
  /**
   * async method
   */
  patchStateApollo(patch: Partial<IGenericTableState>): Promise<void>
  /**
   * async method
   */
  refreshApolloQuery(): Promise<void>
}

export interface IGenericTableState {
  filter: {};
  searchableFields: GenericSearchableFieldsType
  paginator: GenericPaginatorState;
  sorting: GenericSortState;
  searchTerm: string;
  grouping: GenericGroupingState;
}

export declare type GenericSearchableFieldsType = (string | {[key: string]: string[]} )[];
