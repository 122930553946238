import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslationModule} from '../i18n';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {IsGrantedDirective} from './directives/is-granted.directive';
import {InlineSVGModule} from 'ng-inline-svg-2';
import {NoDataTemplateComponent} from './components/no-data-template/no-data-template.component';
import {KtCardComponent} from './components/kt-card/kt-card.component';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import { MyPaginatorComponent } from './components/my-paginator/my-paginator.component';
import { SpinnerModule } from './components/spinner/spinner.module';


@NgModule({
  declarations: [
    IsGrantedDirective,
    NoDataTemplateComponent,
    KtCardComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    TranslationModule,
    InlineSVGModule,
    NgxUiLoaderModule,
    MyPaginatorComponent,
    SpinnerModule
  ],
  exports: [
    CommonModule,
    NgbModule,
    TranslationModule,
    InlineSVGModule,
    IsGrantedDirective,
    NoDataTemplateComponent,
    NgxUiLoaderModule,
    KtCardComponent,
    MyPaginatorComponent,
    SpinnerModule
  ]
})
export class SharedModule {
}
