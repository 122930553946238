import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AuthService} from '../modules/auth';
import {catchError, map} from 'rxjs/operators';
@Injectable()
export class HttpConfigInterceptorInterceptor implements HttpInterceptor {

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {



    const authToken = AuthService.getAuthFromLocalStorage();

    if (null !== authToken) {
      request = request.clone({
          headers: request.headers.set('Authorization', 'Bearer ' + authToken)
        });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse){
          // TODO Remove when finish develop
          /* console.log(event); */
        }
        return event;
      }),
      catchError((error) => {
        if (error instanceof HttpErrorResponse && !request.url.includes('authentication_token') && error.status === 401) {
          console.log('REFRESH TOKEN.')
        }
        console.log('err', error);
        return throwError(error);
      })
    );
  }
}
