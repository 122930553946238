import {ICrudLocale} from "../../i18n/interface";

export const USER_MANAGEMENT_LOCALE_ES: ICrudLocale = {
  USER_MANAGEMENT: {
    LABEL: {
      SINGULAR: 'Usuario',
      PLURAL: 'Usuarios',
    },
    FIELD: {
      NAME: 'Name of Professional',
      USERNAME: 'Usuario',
      EMAIL: 'Email',
      GENDER: 'Género',
      ENABLED: 'Active or Inactive',
      LASTNAME: 'Apellido',
      PASSWORD: 'Contraseña',
      MOBILE: 'Teléfono',
      DNI: 'Dni',
      GROUPS: 'Rol',
      CENTER: 'Center Assignated',
      PATIENT: 'Patient Asignated',
      BIRTHDATE: 'Fecha de nacimiento',
      SELF_GENERATED_PASSWORD: 'Contraseña autogenerada',
    }
    ,
    LIST: {
      TITLE: 'Listado de usuarios',
      FILTERS: 'Filtros',
    },
  }
};
