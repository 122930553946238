import {Injectable, OnDestroy, signal, WritableSignal} from '@angular/core';
import {BehaviorSubject, lastValueFrom, Observable, of, tap} from 'rxjs';
import {catchError, finalize, first, map, switchMap} from 'rxjs/operators';
import {UserModel} from '../models/user.model';
import {TokenModel} from '../models/token.model';
import {AuthHTTPService} from './auth-http';
import {environment} from 'src/environments/environment';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {AccessControl} from '../../../../_config/access-control';
import {ApolloHelperService} from "../../shared/services/apollo-helper.service";
import {gql} from "apollo-angular";

export type UserType = UserModel | undefined;

export const AUTH_USER_QUERY = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      id
      username
      name
      lastname
      email
      gender
      mobile
      roles
      roles
      dni
      enabled
      locale
      birthdate
      lastLogin
      creationTime
      groups {
        collection {
          id
          name
        }
      }
    }
  }
`
export const AUTH_PRO_QUERY = gql`
  query getPro($id: ID!) {
    professional(id: $id) {
      id
      username
      name
      lastname
      email
      gender
      mobile
      roles
      roles
      dni
      enabled
      locale
      birthdate
      lastLogin
      creationTime
      groups {
        collection {
          id
          name
        }
      }
    }
  }
`
export const AUTH_PATIENT_QUERY = gql`
  query getPatient($id: ID!) {
    patient(id: $id) {
      id
      username
      name
      lastname
      email
      gender
      mobile
      roles
      roles
      dni
      enabled
      locale
      birthdate
      lastLogin
      creationTime
      groups {
        collection {
          id
          name
        }
      }
    }
  }
`

export const LOGIN_ROUTE = '/auth/login';


@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {

  public static readonly LOCAL_STORAGE_TOKEN = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  public static readonly AUTH_USER_ID = `${environment.appVersion}-${environment.USERDATA_KEY}-user_id`;
  public static readonly REFRESH_TOKEN = `${environment.appVersion}-refresh_token`;

  // public fields
  currentUser: WritableSignal<UserType> = signal(undefined);

  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;

  private _jwtDecodedToken: any;

  get currentUserValue(): UserType {
    return this.currentUser();
  }

  set currentUserValue(user: UserType) {
    this.currentUser.set(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
    private apolloHelperService: ApolloHelperService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUser.set(undefined)
    this.isLoading$ = this.isLoadingSubject.asObservable();


    this.getUserByToken();
  }

  ngOnDestroy() {
    // this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  // public methods
  login(email: string, password: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      tap((auth: TokenModel) => AuthService.setAuthFromLocalStorage(auth)),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  async logout(reloadPage: boolean = false) {
    AuthService.removeAuthFormLocalStorage();
    this.currentUserValue = undefined;
    await this.router.navigate([LOGIN_ROUTE]);

    if (reloadPage) {
      document.location.reload();
    }

    // this.router.navigate([LOGIN_ROUTE], {
    //   queryParams: {},
    //   replaceUrl: true,
    //   skipLocationChange: true
    // }).then();
  }

  getUserByToken(): Observable<UserType> {
    const token = AuthService.getAuthFromLocalStorage();
    if (undefined === token) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    //TODO: revisar si cambiar por el metodo fetchUserByIRI
    return this.authHttpService.getUserByTokenStorage().pipe(
      first(),
      map(user => {
        // @ts-ignore
        user.id = user['@id'];
        return user
      }),
      tap((user: UserType) => {

        if (!user) {
          this.logout();
          return;
        }

        this.jwtDecodedToken = this.decodeTokenJWT();
        this.currentUserValue = user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user.email, user.password)),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  async refreshToken(): Promise<void> {
    const token$ = this.authHttpService.refreshToken();
    const newToken = await lastValueFrom(token$);
    AuthService.setAuthFromLocalStorage(newToken);
  }

  public static getAuthFromLocalStorage(): string | undefined {
    try {
      const lsValue = localStorage.getItem(AuthService.LOCAL_STORAGE_TOKEN);
      if (!lsValue) {
        return undefined;
      }

      return lsValue;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public static removeAuthFormLocalStorage() {
    localStorage.removeItem(AuthService.LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(AuthService.AUTH_USER_ID);
    localStorage.removeItem(AuthService.REFRESH_TOKEN);
  }

  public isAuthorized(roles: string|string[]) {
    roles = Array.isArray(roles) ? roles : [roles];
    return undefined !== this.currentUserValue && this.currentUserValue.roles.some(role => roles.indexOf(role.toString()) >= 0);
  }

  userHasAccessToRoute(route: ActivatedRouteSnapshot | string | null = null): Promise<boolean> {
    return new Promise(async (resolve) => {

      if (!AccessControl.CHECK_ACCESS) {
        resolve(true);
        return;
      }

      //get the roles of the authenticated user
      const userRoles = this.currentUserValue?.roles;

      //user has no roles
      if (undefined === userRoles || 0 === userRoles.length) {

        if (AccessControl.GRANT_ACCESS_IF_USER_HAS_NO_ROLE) {
          resolve(true);
        } else {
          resolve(false);
        }

        return;
      }

      //get all allowed route by value
      const accessControl: any = AccessControl.getAccessControlListByRole()
      let permits: any[] = [];

      //we get all permissions for each user value
      userRoles.forEach(userRole => {
        if (accessControl.hasOwnProperty(userRole)) {
          permits.push(...accessControl[userRole]);
        }
      });

      if (!permits.includes(route)) {

        resolve(false);

        // In the case of bad configuration that by mistake we define that the route to redirect is a
        // route under access check and our user does not have access to said route will be logged out of the platform
        // and redirected to login.
        // And if the initial page of our platform after login is the same as redirection route then our user will never be able to access the platform
        if (AccessControl.REDIRECT_ON_ACCESS_DENIED_ROUTE.path === route) {
          await this.logout();
          return
        }

        await this.router.navigateByUrl(AccessControl.REDIRECT_ON_ACCESS_DENIED_ROUTE.url)
        return;
      }


      resolve(true);

    });
  }

  /**
   * Decode the JWT token used to authenticate, so we can get the metadata sent in the token
   */
  get jwtDecodedToken(): any {
    return this._jwtDecodedToken;
  }

  set jwtDecodedToken(value: any) {
    this._jwtDecodedToken = value;
  }

  private decodeTokenJWT(): undefined | any {
    const token = AuthService.getAuthFromLocalStorage();
    if (!token) {
      return undefined;
    }
    return (new JwtHelperService()).decodeToken(token);
  }

  private static setAuthFromLocalStorage(auth: TokenModel): boolean {
    if (auth && auth.token) {
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(auth.token);
      localStorage.setItem(AuthService.LOCAL_STORAGE_TOKEN, auth.token);
      localStorage.setItem(AuthService.AUTH_USER_ID, decodedToken.user.id);
      localStorage.setItem(AuthService.REFRESH_TOKEN, auth.refresh_token);
      return true;
    }
    return false;
  }

  async fetchUserByIRI(iri: string, freezeResults: boolean = true) {
    return this.apolloHelperService.fetchById({
      query: AUTH_USER_QUERY,
      id: iri,
      processResult: true,
      freezeResults
    });
  }

  async fetchProfessionalByIRI(iri: string, freezeResults: boolean = true) {
    return this.apolloHelperService.fetchById({
      query: AUTH_PRO_QUERY,
      id: iri,
      processResult: true,
      freezeResults
    });
  }

  async fetchPatientByIRI(iri: string, freezeResults: boolean = true) {
    return this.apolloHelperService.fetchById({
      query: AUTH_PATIENT_QUERY,
      id: iri,
      processResult: true,
      freezeResults
    });
  }

  isAdmin(): boolean {
    return <boolean>this.currentUserValue?.roles.includes('ROLE_ADMIN');
  }
}
