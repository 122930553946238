<ng-template #rowTemplate let-item="data" let-index="index">

  <td>{{ index }}</td>
  <td>{{ item.name }} </td>
  <td>{{ item.username }}</td>
  <td>{{ item.email }}</td>
  <td>{{ item.gender | authGenderConverter }}</td>
  <td>
    <label class="form-check form-switch form-check-custom form-check-solid">
      <input (change)="toggleUser(item, $event)"
             [checked]="item.enabled"
             [disabled]="item.id | isCurrentUserId: 'iri'"
             class="form-check-input h-30px w-50px"
             type="checkbox"
      />
    </label>
  </td>
  <td class="text-end">
    <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm mx-1"
       (click)="form(item.id)">
      <i class="fa fa-pencil"></i>
    </a>
    <a class="btn btn-icon btn-bg-light btn-active-color-danger btn-sm mx-1"
       *ngIf="!(item.id | isCurrentUserId: 'iri')"
       (click)="delete(item.id)">
      <i class="fa fa-trash "></i>
    </a>
  </td>

</ng-template>

<ng-template #btnTemplate let-isTableFilterActive="isTableFilterActive">

  <button type="button" class="btn btn-light-primary ms-3" data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end">
    <span class="svg-icon svg-icon-2">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
          fill="black"/>
      </svg>
    </span>
    {{'USER_MANAGEMENT.LIST.FILTERS' | translate}}
    <i *ngIf="isTableFilterActive" class="fa fa-circle " style="font-size: 5px;margin-bottom: 16px"></i>
  </button>
  <div class="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true">
    <!--    <div class="px-7 py-5">-->
    <!--      <div class="fs-5 text-dark fw-bolder">Opciones</div>-->
    <!--    </div>-->
    <div class="separator border-gray-200"></div>

    <div class="px-7 py-5" data-kt-user-table-filter="form">

      <div class="mb-10">
        <label class="form-label fs-6 fw-bold">{{ 'USER_MANAGEMENT.FIELD.GENDER' |translate}}:</label>
        <select [(ngModel)]="genderFilter" class="form-select form-select-solid fw-bolder">
          <option value="" selected="selected" translate="ACTION.SELECT.GENERIC"></option>
          <option *ngFor="let gender of genders" [value]="gender.value">{{gender.name | translate}}</option>
        </select>
      </div>
      <div class="mb-10">
        <label class="form-label fs-6 fw-bold">Rol:</label>
        <select [(ngModel)]="roleFilter" class="form-select form-select-solid fw-bolder">
          <option value="" selected="selected">Seleccionar</option>
          <option *ngFor="let role of roles" [value]="role.value">{{role.name | authRoleConverter}}</option>
        </select>
      </div>
      <div class="mb-10">
        <label class="form-label fs-6 fw-bold">{{ 'USER_MANAGEMENT.FIELD.ENABLED' |translate}}:</label>
        <label class="form-check form-switch form-check-custom form-check-solid">
          <input [(ngModel)]="enabledFilter"
                 class="form-check-input h-30px w-50px"
                 type="checkbox"
          />
        </label>
      </div>

      <div class="d-flex justify-content-end">
        <button type="reset" (click)="resetFilter()" class="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
                data-kt-menu-dismiss="true">{{'ACTION.REMOVE.CLEAN' | translate}}
        </button>
        <button type="submit" (click)="applyFilter()" class="btn btn-primary fw-bold px-6" data-kt-menu-dismiss="true"
                data-kt-user-table-filter="filter">{{'ACTION.APPLY.GENERIC' | translate}}
        </button>
      </div>

    </div>

  </div>

  <a type="button" class="btn btn-primary ms-3" (click)="form()"> {{ 'ACTION.ADD.GENERIC'| translate }}
  </a>
</ng-template>

<!-- <app-generic-table-page
  [title]="'USER_MANAGEMENT.LIST.TITLE'"
  [actionsTemplate]="btnTemplate"
  [genericTableFilters$]="tableFilters"

  [headers]="headers"
  [rowTemplate]="rowTemplate"
  [genericTableService]="userService"
></app-generic-table-page> -->

<app-generic-table-page
  [title]="'LISTA DE PROFESIONALES'"
  [actionsTemplate]="btnTemplate"
  [genericTableFilters$]="tableFilters"

  [headers]="headers"
  [rowTemplate]="rowTemplate"
  [genericTableService]="userService"
></app-generic-table-page>
