<div class="card card-custom gutter-b">
  <ngx-ui-loader [loaderId]="ngxUILoader"></ngx-ui-loader>
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">
        {{ (undefined === genericEntityId ? 'ACTION.ADD.GENERIC' : 'ACTION.EDIT.GENERIC') | translate}} {{ 'USER_MANAGEMENT.LABEL.SINGULAR' | translate }}
      </h3>
    </div>
    <div class=" d-flex align-items-center me-0">
      <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" (click)="dismissModal()">
        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
        <span class="svg-icon svg-icon-2x">
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none">
																			<rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                            transform="rotate(-45 6 17.3137)" fill="black"></rect>
																			<rect x="7.41422" y="6" width="16" height="2" rx="1"
                                            transform="rotate(45 7.41422 6)" fill="black"></rect>
																		</svg>
																	</span>
        <!--end::Svg Icon-->
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="mt-5">
      <form action="#" class="form form-label-right" (keydown.enter)="$event.preventDefault()"
            (keyup.enter)="$event.preventDefault()" [formGroup]="form">
        <!-- name lastname-->
        <div class="form-group row mb-5">
          <div class="col-lg-6">
            <label>{{'USER_MANAGEMENT.FIELD.NAME' | translate }} <span class="text-danger">*</span></label>
            <input type="text"
                   class="form-control"
                   name="name"
                   formControlName="name"
                   [class.is-invalid]="isControlInvalid('name')"
                   [class.is-valid]="isControlValid('name')"/>
            <div class="invalid-feedback">
              <ng-template [ngIf]="controlHasError('required', 'name')">
                <span translate="VALIDATION.REQUIRED"></span>
              </ng-template>
              <ng-template [ngIf]="controlHasErrorFromApi('name')">
                <span [translate]="getControlErrorFromApi('name')"></span>
              </ng-template>
            </div>
          </div>
          <div class="col-lg-6 mt-5 mt-lg-0">
            <label>{{'USER_MANAGEMENT.FIELD.LASTNAME' | translate }} <span class="text-danger">*</span></label>
            <input type="text"
                   class="form-control"
                   name="lastname"
                   formControlName="lastname"
                   [class.is-invalid]="isControlInvalid('lastname')"
                   [class.is-valid]="isControlValid('lastname')"
            />
            <div class="invalid-feedback" *ngIf="controlHasError('required', 'lastname')">
              <span translate="VALIDATION.REQUIRED"></span>
            </div>
          </div>
        </div>
        <!-- username email phone dni-->
        <div class="form-group row mb-5">
          <div class="col-lg-12 mb-5">
            <label>{{'USER_MANAGEMENT.FIELD.USERNAME' | translate }} <span class="text-danger">*</span></label>
            <input
              type="text"
              class="form-control"
              name="username"
              formControlName="username"
              [class.is-invalid]="isControlInvalid('username')"
              [class.is-valid]="isControlValid('username')"
            />
            <div class="invalid-feedback">
              <ng-template [ngIf]="controlHasError('required', 'username')">
                <span translate="VALIDATION.REQUIRED"></span>
              </ng-template>
              <ng-template [ngIf]="controlHasErrorFromApi('username')">
                <span [translate]="getControlErrorFromApi('username')"></span>
              </ng-template>
            </div>
          </div>
          <div class="col-lg-12 mb-5">
            <label>{{'USER_MANAGEMENT.FIELD.EMAIL' | translate }} <span class="text-danger">*</span></label>
            <input
              type="text"
              class="form-control"
              name="email"
              formControlName="email"
              required
              [class.is-invalid]="isControlInvalid('email')"
              [class.is-valid]="isControlValid('email')"
            />
            <div class="invalid-feedback">
              <ng-template [ngIf]="controlHasError('email', 'email')">
                <span translate="VALIDATION.EMAIL"></span>
              </ng-template>
              <ng-template [ngIf]="controlHasError('required', 'email')">
                <span translate="VALIDATION.REQUIRED"></span>
              </ng-template>
              <ng-template [ngIf]="controlHasErrorFromApi('email')">
                <span [translate]="getControlErrorFromApi('email')"></span>
              </ng-template>
            </div>
          </div>
          <div class="col-lg-12 mb-5">
            <label>{{'USER_MANAGEMENT.FIELD.DNI' | translate }}</label>
            <input
              type="text"
              class="form-control"
              name="dni"
              formControlName="dni"
              [class.is-invalid]="isControlInvalid('dni')"
              [class.is-valid]="isControlValid('dni')"
            />
            <div class="invalid-feedback">
              <ng-template [ngIf]="controlHasError('required', 'dni')">
                <span translate="VALIDATION.REQUIRED"></span>
              </ng-template>
              <ng-template [ngIf]="controlHasError('maxlength', 'dni')">
                <span>{{'VALIDATION.MAX_LENGTH' | translate : { max: form.controls.dni.errors?.maxlength?.requiredLength, actual: form.controls.dni.errors?.maxlength?.actualLength } }}</span>
              </ng-template>
              <ng-template [ngIf]="controlHasErrorFromApi('dni')">
                <span [translate]="getControlErrorFromApi('dni')"></span>
              </ng-template>
            </div>
          </div>
          <div class="col-lg-12">
            <label>{{'USER_MANAGEMENT.FIELD.MOBILE' | translate }}</label>
            <input type="text"
                   class="form-control"
                   name="mobile"
                   formControlName="mobile"
                   [class.is-invalid]="isControlInvalid('mobile')"
                   [class.is-valid]="isControlValid('mobile')"/>
            <div class="invalid-feedback" *ngIf="controlHasError('required', 'mobile')">
              <span translate="VALIDATION.REQUIRED"></span>
            </div>
          </div>
        </div>

        <div class="form-group row mb-5">
          <div class="col-lg-12 mb-5">
            <label>{{'USER_MANAGEMENT.FIELD.BIRTHDATE' | translate }}</label>
            <input
              type="text"
              class="form-control"
              formControlName="birthdate"
              [placeholder]="'USER_MANAGEMENT.FIELD.BIRTHDATE' | translate"
              mwlFlatpickr
            />

          </div>
        </div>


        <!--gender-->
        <div class="form-group row mb-5">
          <div class="col-lg-12 mb-5">
            <label>{{'USER_MANAGEMENT.FIELD.GENDER' | translate }}</label>

            <ng-select
              [class.is-invalid]="isControlInvalid('gender')"
              [class.is-valid]="isControlValid('gender')"
              [placeholder]="'TEXT.SELECT' | translate"
              [notFoundText]="'TEXT.NO_ITEMS_FOUND' | translate"
              formControlName="gender"
            >
              <ng-option *ngFor="let gender of allGenders" [value]="gender.value"> {{ gender.name | translate}}</ng-option>
            </ng-select>

            <div class="invalid-feedback">
              <ng-template [ngIf]="controlHasError('required', 'gender')">
                <span translate="VALIDATION.REQUIRED"></span>
              </ng-template>
              <ng-template [ngIf]="controlHasErrorFromApi('gender')">
                <span [translate]="getControlErrorFromApi('gender')"></span>
              </ng-template>
            </div>
          </div>
        </div>
        <!--groups-->
        <div class="form-group row mb-5">
          <div class="col-lg-12 mb-5">
            <label>{{'USER_MANAGEMENT.FIELD.GROUPS' | translate }}</label>

            <ng-select
              [items]="allGroups"
              bindLabel="name"
              bindValue="id"
              [multiple]="true"
              [placeholder]="'TEXT.SELECT' | translate"
              [notFoundText]="'TEXT.NO_ITEMS_FOUND' | translate"
              [class.is-invalid]="isControlInvalid('groups')"
              [class.is-valid]="isControlValid('groups')"
              formControlName="groups"
            >
            </ng-select>
            <div class="invalid-feedback">
              <ng-template [ngIf]="controlHasError('required', 'groups')">
                <span translate="VALIDATION.REQUIRED"></span>
              </ng-template>
              <ng-template [ngIf]="controlHasErrorFromApi('groups')">
                <span [translate]="getControlErrorFromApi('groups')"></span>
              </ng-template>
            </div>
          </div>
        </div>




        <!--enabled-->
        <div class="form-group row mb-5">
          <div class="col-lg-12 mb-5">
            <label>{{'USER_MANAGEMENT.FIELD.ENABLED' | translate }}</label>
            <label class="form-check form-switch form-check-custom form-check-solid">
              <input
                [checked]="entity.enabled"
                name="enabled"
                formControlName="enabled"
                [class.is-invalid]="isControlInvalid('enabled')"
                [class.is-valid]="isControlValid('enabled')"
                class="form-check-input h-30px w-50px"
                type="checkbox"
              />
            </label>
          </div>
        </div>
        <!--password-->
        <div class="form-group row">
          <ng-container *ngIf="!genericEntityId">
            <div class="mb-1">
              <label>{{'USER_MANAGEMENT.FIELD.PASSWORD' | translate }} <span class="text-danger">*</span>
                <a (click)="generateRandomPassword()"
                   ngbTooltip="Generar nueva contraseña"
                   class="btn btn-icon btn-active-color-primary btn-sm p-0 m-0">
                  <i class="fa fa-refresh"></i>
                </a>
              </label>
                <input
                  passwordSecurity
                  enablePasswordReactiveValidation="true"
                  formControlName="password"
                  type="password"
                  autocomplete="false"
                  class="form-control"
                  [class.is-invalid]="isControlInvalid('password')"
                  [class.is-valid]="isControlValid('password')"
                />
              <div class="invalid-feedback">
                <ng-template [ngIf]="controlHasError('required', 'password')">
                  <span translate="VALIDATION.REQUIRED"></span>
                </ng-template>
                <ng-template [ngIf]="controlHasError('passwordStrength', 'password')">
                  <span
                    translate="VALIDATION.PASSWORD_STRENGTH"
                    [translateParams]="{max: getControlError('passwordStrength','password')?.maxLength}"></span>
                </ng-template>
              </div>
            </div>
            <small class="form-text text-muted">

              {{'USER_MANAGEMENT.FIELD.SELF_GENERATED_PASSWORD' | translate}}: <b>{{generatePassword}}</b>
            </small>
          </ng-container>
        </div>

        <div class="card-footer d-flex float-right justify-content-end mt-10">
          <button class="btn btn-secondary font-weight-bold me-2"
                  (click)="dismissModal()">
            {{'ACTION.CLOSE.GENERIC' | translate}}
          </button>
          <button (click)="onFormSave()" type="submit" class="btn btn-primary font-weight-bold mr-2">
            {{'ACTION.SAVE.GENERIC' | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
