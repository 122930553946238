<!-- start: Paginator -->
<div class="w-100 p-4 d-flex justify-content-between align-items-center" style="background: #EFF3FD">
  <div class="d-flex align-items-center py-3" >
    <!-- begin: loading -->
    <div class="d-flex align-items-center" *ngIf="showLoading && isLoading">
      <div class="mr-2 text-muted">{{ "PAGINATOR.LOADING" | translate }}...</div>
      <div class="spinner spinner-primary mr-10"></div>
    </div>
    <!-- end: loading -->

    <!-- begin: pageSize -->
    <div class="d-flex align-items-center gap-2">
      <label class="H14-500-inter my-text-strong ">Showing from</label>
      <select *ngIf="showPageSize"  class="bg-white py-3 rounded rounded-1" style="width: 75px" [(ngModel)]="paginatorState.itemsPerPage" (change)="sizeChange()">
        <option class="btn" *ngFor="let ps of paginatorState.pageSizes">
          {{ ps }}
        </option>
      </select>
    </div>

    <span *ngIf="showPageInfo" class="bootstrap-table-pagination pagination-total">
      &nbsp;{{ "PAGINATOR.SHOWING_ROWS" | translate }} {{ paginatorState.startRowIndex }} {{ "PAGINATOR.TO" | translate }}&nbsp;{{ paginatorState.endRowIndex}}  {{ "PAGINATOR.OF" | translate }}&nbsp;{{ paginatorState.totalCount }}
      </span>
    <!-- end: pageSize -->
  </div>
  <div class="d-flex" style="width: max-content">
    <ng-pagination
      *ngIf="showPages"
      [collectionSize]="paginatorState.totalCount"
      [(page)]="paginatorState.page"
      [maxSize]="4"
      [rotate]="true"
      [boundaryLinks]="true"
      [pageSize]="paginatorState.itemsPerPage"
      (pageChange)="pageChange($event)"
    ></ng-pagination>
  </div>
</div>

<!-- end: Paginator -->
