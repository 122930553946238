import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerService } from '../../services/spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {

  isLoading$: any ;

  constructor(private spinnerSvc: SpinnerService){
    this.loadSpinner();
  }

  async loadSpinner(){
    this.isLoading$ = await  this.spinnerSvc.isLoading$;
  }

}
