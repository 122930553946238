import {GenericTableService} from '../../services/table.service';
import {FormControl, FormGroup} from '@angular/forms';
import {SweetAlertService} from '../../../../services/sweet-alert.service';
import {GenericPaginatorState} from '../../models/paginator.model';
import {GenericSortState, SortDirectionOptions} from '../../models/sort.model';
import {GenericGroupingState} from '../../models/grouping.model';
import {BehaviorSubject, Subscription} from 'rxjs';
import {inject} from "@angular/core";
import {HelperTools} from "../../../../services/helperTools";

/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export interface IConfigureList {
  tableService: GenericTableService<any>
  searchFilter?: {
    enabled?: boolean,
    inputDelay?: number
  }
}

/**
 * Permite establecer los filtros a usar en el query, por defecto null o objeto vacio resetea los valores de los filtros en el query
 *
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export type GenericTableFilter = Record<any, any> | null

/**
 * Abstract class that standardizes the work with generic listings
 *
 * Public access members
 * - `genericPaginatorState: GenericPaginatorState`
 * - `genericSearchGroup: FormGroup`
 * - `genericSearchFormControlName: string` (Default value "searchTerm")
 * - `genericSortingState: GenericSortState`
 * - `genericGroupingState: GenericGroupingState`
 *
 * Services DI
 * - `protected sweetAlertService: SweetAlertService`
 *
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export abstract class AbstractGenericListHandler<T> {

  public genericTableFilters$: BehaviorSubject<GenericTableFilter> = new BehaviorSubject<GenericTableFilter>(null);

  public genericSortingState: GenericSortState;
  public genericGroupingState: GenericGroupingState;
  public genericPaginatorState: GenericPaginatorState;
  public genericSearchGroup: FormGroup;
  public genericSearchFormControlName: string = 'searchTerm';
  public isTableFilterActive = false;

  //Services DI
  protected readonly sweetAlertService: SweetAlertService = inject(SweetAlertService);

  private genericTableSubscriptions: Subscription[];
  private _tableServiceInstance: GenericTableService<T>

  /**
   * Method in which we can configure our list dynamically
   * for more information review the IConfigureList interface
   * @return IConfigureList
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public abstract configureList(): IConfigureList

  public initGenericListHandler(): void {
    this.genericTableSubscriptions = [];

    this._tableServiceInstance = this.configureList().tableService;
    const searchConfig = this.configureList().searchFilter;

    //call table service onInit
    this._tableServiceInstance.onInit();

    if (searchConfig?.enabled) {
      this.initSearchFilter(searchConfig?.inputDelay);
    }

    //fetch initial data form server
    this._tableServiceInstance.fetchApollo();

    //init all generic table vars
    this.genericPaginatorState = this._tableServiceInstance.paginator;
    this.genericGroupingState = this._tableServiceInstance.grouping;
    this.genericSortingState = this._tableServiceInstance.sorting;

    //table subscriptions
    const hasTableErrorSubscription = this._tableServiceInstance.errorMessage$.subscribe(async message => {
      if (message.trim().length > 0) {
        await this.alertFromTableError(message);
      }
    });
    const isFilterActiveSubscription = this._tableServiceInstance.isTableFilterActive$.subscribe(state => this.isTableFilterActive = state);
    const tableFiltersSubscription = this.genericTableFilters$.subscribe(async filters => await this.applyFilterPatch(filters));

    this.genericTableSubscriptions.push(tableFiltersSubscription);
    this.genericTableSubscriptions.push(isFilterActiveSubscription);
    this.genericTableSubscriptions.push(hasTableErrorSubscription);
  }

  public destroyGenericListHandler(): void {
    this._tableServiceInstance.onDestroy();
    this._tableServiceInstance.resetTableState()
    this._tableServiceInstance.setItems([]);
    this._tableServiceInstance.setIsLoading(false);
    this._tableServiceInstance.defaultQueryFilters = {};
    this._tableServiceInstance.tableSearchableFields = [];
    this.genericTableSubscriptions?.forEach(sb => sb.unsubscribe())
    this._tableServiceInstance.querySubscription?.unsubscribe();
  }

  /**
   * Initialize the global search term filter
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   * @param delay
   */
  public initSearchFilter(delay: number = 500) {
    this.genericSearchGroup = new FormGroup({
      [this.genericSearchFormControlName]: new FormControl(''),
    });

    const inputValues$ = this.genericSearchGroup.controls[this.genericSearchFormControlName]?.valueChanges;

    const searchEvent = HelperTools.delayObservable(inputValues$, delay)
      .subscribe(
        (searchTerm) => this._tableServiceInstance.patchStateApollo({searchTerm})
      );
    this.genericTableSubscriptions.push(searchEvent);
  }

  /**
   * Gets the observable with the items to subscribe
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  getListItems$() {
    return this._tableServiceInstance.items$;
  }

  /**
   * Observable to determine when the table is loading
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  getListIsLoading$() {
    return this._tableServiceInstance.isLoading$;
  }

  /**
   * Allows you to refresh the query specified for the table
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public async applyRefreshPatch(): Promise<void> {
    await this._tableServiceInstance.refreshApolloQuery()
  }

  /**
   * Method that receives the event of the paginator component
   * to apply the changes in the list
   *
   * @param paginator GenericPaginatorState
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public applyPaginatorPatch(paginator: GenericPaginatorState): void {
    this._tableServiceInstance.patchStateApollo({paginator}).then();
  }

  /**
   * Method that receives the event of the paginator component
   * to apply the changes in the list
   *
   * @param column string
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public applySortPatch(column: string): void {
    const sorting = this.genericSortingState;
    const isActiveColumn = sorting.column === column;
    if (!isActiveColumn) {
      sorting.column = column;
      sorting.direction = SortDirectionOptions.asc;
    } else {
      sorting.direction = sorting.direction === SortDirectionOptions.asc ? SortDirectionOptions.desc : SortDirectionOptions.asc;
    }
    this._tableServiceInstance.patchStateApollo({sorting}).then();
  }

  /**
   * Apply filters to the table
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  private async applyFilterPatch(filter: object | null): Promise<void> {
    await this._tableServiceInstance.patchStateApollo({filter: filter ?? {}});
  }

  /**
   * This method is used to overwrite the alert system in case of errors in the table
   * @param message
   *
   * @example 1
   *  public async alertFromTableError(message: string) {
   *    await this.sweetAlert.error({
   *       text: message
   *     });
   *   }
   *
   * @example 2
   *  public async alertFromTableError(message: string) {
   *    await this.toaster.error(message);
   *   }
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public async alertFromTableError(message: string) {
    await this.sweetAlertService.error({
      text: message
    })
  }
}
