import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Patient, User, UserExtended} from "../../model/user";
import {UserInstanceEnum, UserService} from "../../services/user.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {QUERIES_USER_MANAGEMENT} from "../../_graphql/_queries";
import {BaseGroup} from "../../../shared/model/base-group";
import {CryptoService} from "../../../shared/services/crypto.service";
import {
  AbstractGenericFormHandler,
  IConfigureForm
} from "../../../shared/modules/crud-table/abstract/generic-form/generic-form";
import {ApolloMutationResult, MUTATION_ACTION, MutationAction} from "../../../shared/services/apollo-helper.service";
import {AuthService} from "../../../auth";
import {HelperTools} from "../../../shared/services/helperTools";
import {AVAILABLE_GENDERS, IGender} from "../../../../../_config/genders";
import {PartialExtended} from "../../../shared/model/types";

@Component({
  selector: 'app-user-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent extends AbstractGenericFormHandler<UserExtended> implements OnInit, OnDestroy {

  currentUserId: number | null = Number(localStorage.getItem(AuthService.AUTH_USER_ID));
  activeInstanceName: string = UserInstanceEnum.user;

  public allGenders: IGender[] = AVAILABLE_GENDERS;
  public allGroups: BaseGroup[] = [];

  public _generatePassword: string = '';

  constructor(
    private userService: UserService,
    private cryptoService: CryptoService,
    private ngbModalRef?: NgbModal
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    if (this.genericEntityId) {
      this.activeInstanceName = this.userService.inheritUserInstanceFromID(this.genericEntityId)
    }

    await this.initGenericFormHandler();
    this.generateRandomPassword();
  }

  async ngOnDestroy(): Promise<void> {
    await this.destroyGenericFormHandler()
  }

  /**
   * @inheritDoc
   */
  configureForm(): IConfigureForm {
    return {
      crudService: this.userService,
      processSuccessMutationResult: false,
      allowedDataModels: [
        {
          class: User,
          default: UserInstanceEnum.user === this.activeInstanceName,
          query: QUERIES_USER_MANAGEMENT.user.findById,
        },
        {
          class: Patient,
          default: UserInstanceEnum.patient === this.activeInstanceName,
          query: QUERIES_USER_MANAGEMENT.patient.findById,
        },
      ],
      cleanFormOnCreate: true,
    };
  }

  /**
   * @inheritDoc
   */
  buildForm(builder: FormBuilder): FormGroup {
    return builder.group({
      username: [this.entity.username, Validators.compose([Validators.required])],
      mobile: [this.entity.mobile],
      email: [this.entity.email, Validators.compose([Validators.required, Validators.email])],
      name: [this.entity.name, Validators.compose([Validators.required])],
      locale: [this.entity.locale],
      enabled: [this.entity.enabled],
      lastname: [this.entity.lastname, Validators.compose([Validators.required])],
      password: [this.generatePassword, Validators.compose([Validators.required])],
      dni: [this.entity.dni, Validators.compose([Validators.maxLength(9)])],
      gender: [this.entity.gender],
      birthdate: [HelperTools.epochToDate(this.entity.birthdate)],
      groups: [this.entity.groups?.map(group => group.id)],
      roles: [this.entity.roles],
    });
  }

  //overwritten abstract generic form logic
  /**
   * @inheritDoc
   */
  onBuildFormFinish() {
    if (this.getFormAction() === MUTATION_ACTION.update) {
      this.form.removeControl('password')
      if (this.currentUserId === HelperTools.iriToId(this.entity.id)) {
        this.form.get('enabled')?.disable();
      }

      if (UserInstanceEnum.patient === this.entityInstanceToString()) {
        this.form.get('gender')?.disable();
      }

    }
  }

  async onMutationSuccess(result: ApolloMutationResult) {
    super.onMutationSuccess(result);
    await this.userService.refreshApolloQuery()
  }

  dataFormModelTransformerBeforeSend(action: MutationAction, formData: PartialExtended<UserExtended>) {
    if (MUTATION_ACTION.create === action) {
      formData.password = this.cryptoService.encrypt(formData.password);
    }

    formData.birthdate = HelperTools.dateToEpoch(new Date(formData.birthdate ?? Date.now()));

    return formData;
  }

  async preFetchDataAfterBuildReactiveForm() {
    let groupCollection = await this.userService
      .fetchAllGroupsTypes(this.isProcessingForm$)
      .catch(reason => {
        this.ngbModalRef?.dismissAll()
        this.sweetAlertService.error()
        console.error(reason)
      });

    if (groupCollection) {
      this.allGroups = groupCollection
    }
  }

  //user component logic
  public dismissModal() {
    this.ngbModalRef?.dismissAll();
  }

  get generatePassword(): string {
    return this._generatePassword;
  }

  generateRandomPassword() {
    this._generatePassword = HelperTools.generateHash({length: 10});
    this.form?.get('password')?.setValue(this._generatePassword);
  }

}
