import {FormBuilder, FormGroup} from '@angular/forms';
import {BehaviorSubject, Subscription} from 'rxjs';
import {SweetAlertService} from '../../../../services/sweet-alert.service';
import {CustomLoaderService} from '../../../../services/custom-loader.service';
import {FormTools} from '../../../../services/formTools';
import {
  ApolloFormError,
  ApolloHelperService,
  ApolloMutation,
  ApolloMutationResult,
  ApolloQuery,
  MUTATION_ACTION,
  MutationAction
} from '../../../../services/apollo-helper.service';
import {GenericCRUDTableService} from '../../services/crud.table.service';
import {AbstractBaseEntity} from '../../../../model/abstract-base-entity';
import {DecoratorReflectMetadata} from './decorator-reflect-metadata';
import {inject} from '@angular/core';
import {PartialExtended} from "../../../../model/types";
import {HelperTools} from "../../../../services/helperTools";


/**
 * Allows you to establish the logic for each API resource class in a generic and dynamic way,
 * It is also possible to set new keys as metadata for each model and be accessible from activeAllowedDataModel
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
interface IAllowedDataModel {
  /**
   * API resource class to use in the form, debe implementar @ApiResourceName decorator.
   */
  class: any,
  /**
   * Defines if it is the default model to use, in case there is more than one.
   */
  default?: boolean,
  /**
   * Specifies the query to use for this model, this query is used to fetch the server and return the
   * object to fill the form in edit mode
   */
  query?: ApolloQuery,
  /**
   * Specifies which fields of the class will be returned after performing the mutation.
   * This configuration key is not used in conjunction with customMutations
   */
  mutationSubQueryFields?: string[],
  /**
   * Allows you to define custom mutations for each defined action of the form
   */
  customMutations?: { [key in Exclude<keyof typeof MUTATION_ACTION, 'isAllowedValue' | 'getAllValues' | 'delete'> | string]?: ApolloMutation },

  [key: string]: any;
}

/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export interface IConfigureForm {
  /**
   * Service that extends CRUDTableService to be able to handle the form.
   */
  crudService: GenericCRUDTableService<any>,
  /**
   * Allows you to define which models will be used to work on the form, there should only be one by default,
   * if not specify the query key the fetch is not done when editing the element.
   *
   */
  allowedDataModels: IAllowedDataModel[],
  /**
   * Clear the form when the create mutation returns correctly.
   * By default, the form does not clean the values when creating the instance
   *
   *  @default false | undefined
   */
  cleanFormOnCreate?: boolean,
  /**
   *  Key that defines what data to send in the mutation,
   *  when the value is true only the fields defined in the reactive form are sent,
   *  when it is false a merge is created between the fields of the entity and those of the form
   *
   *  @default true
   *
   */
  sendOnlyFormFields?: boolean,
  /**
   * Defines whether the handler should process the response of the mutation carried out, it is very useful when our mutation
   * perform a subquery with data from the mutated resource, for the generic case the active model must have set the
   * key "mutationSubQueryFields"
   */
  processSuccessMutationResult?: boolean,
  /**
   *  Allows you to define if the transformation of the form fields is done by reference in memory or as
   *  one separate copy at a time
   *
   *  This can impact the app's performance if the form contains many nested object or array properties.
   *
   *  @default false
   *
   */
  createFromDataDeepClone?: boolean,
  /**
   * Key to configure the validation of the generic form
   */
  formValidations?: {
    /**
     * Executes the validation of each formControl even if it was not modified at the time of submitting the form to the server
     * in case of errors the form is marked as invalid,
     *
     * @default true
     */
    validateFieldsOnSubmit?: boolean,
    /**
     * It allows to propagate the error of the fields when the mutation returns from the server with errors from the server,
     * is used in conjunction with the propagateErrorAs key.
     *
     * @default true
     */
    applyFieldErrorFromServer?: boolean,
    /**
     * Defines the name of the validation rule that we will be listening to in case of errors on the server with the entity
     * the default value in case of not specifying would be "api_error"
     *
     * @default api_error
     */
    propagateErrorAs?: string,
  }
}

const PROPAGATE_ERROR_AS = 'api_error';

/**
 * Abstract class that standardizes the work with reactive forms in a dynamic way
 *
 *
 * Public access members
 * - `genericEntityId: string` (Variable used to switch to edit mode and load the DataAllowedModel data)
 * - `ngxUILoader: string` (Variable that defines the value of the loaderID to be used in the views)
 *
 * Services DI
 * - `protected sweetAlertService: SweetAlertService`
 * - `protected loaderUIService: CustomLoaderService`
 *
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export abstract class AbstractGenericFormHandler<T extends AbstractBaseEntity> {

  public ngxUILoader: string = 'generic-form-' + self.crypto.randomUUID();
  public genericEntityId: string;

  //Services DI
  protected readonly sweetAlertService: SweetAlertService = inject(SweetAlertService);
  protected readonly loaderUIService: CustomLoaderService = inject(CustomLoaderService);
  private readonly fb: FormBuilder = inject(FormBuilder);

  private _form: FormGroup;
  private _entity: T;
  private _activeAllowedDataModel: IAllowedDataModel;
  private _crudServiceInstance: GenericCRUDTableService<T>;
  private _isFormReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private _isProcessingForm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private subscriptions: Subscription[] = [];


  /**
   * Method in which we can configure our crud dynamically
   * for more information review the IConfigureForm interface
   * @return IConfigureForm
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  abstract configureForm(): IConfigureForm;

  /**
   * Method where we create the reactive form
   *
   * @return FormGroup
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  abstract buildForm(builder: FormBuilder): FormGroup;

  /**
   * Initialize our handler
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public async initGenericFormHandler(): Promise<void> {
    const defaultAllowedDataModel = this.getDefaultAllowedDataModel()

    if (!defaultAllowedDataModel) {
      throw new Error('You have not defined any instance by default, in the "allowedDataModels" key in the configureCrud()')
    }

    this.activeAllowedDataModel = defaultAllowedDataModel;
    this.subscriptions.push(this.isFormReady$.subscribe(value => {
      if (!value) {
        this.loaderUIService.startLoader(this.ngxUILoader)
        return;
      }
      this.loaderUIService.stopLoader(this.ngxUILoader)

    }))
    this.subscriptions.push(this.isProcessingForm$.subscribe(value => {
      this.isFormReady$.next(!value)
    }))

    this._crudServiceInstance = this.configureForm().crudService;

    if (undefined === this.activeAllowedDataModel.class.name) {
      throw new Error('The value of instanceClass is not a valid class instance.')
    }

    //create an instance of the entity
    this.createNewEntityInstance()
    //We start the form with the default data so that the view has access from the beginning
    this.buildReactiveForm();

    this.isProcessingForm$.next(true)

    await Promise.all([
      this.preFetchDataAfterBuildReactiveForm(),
      this.findOneInstanceByID(),
    ]);

    //We re-initialize the form again but now with all the data loaded
    //but only if we are editing or what would be the same if the genericEntityId has any value different from undefined
    if (undefined !== this.genericEntityId) {
      this.buildReactiveForm();
    }

    this.isProcessingForm$.next(false)
  }

  /**
   * Destroys our handler
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public async destroyGenericFormHandler(): Promise<void> {
    this.subscriptions.forEach(sb => {
      sb.unsubscribe();
    });
  }

  //<editor-fold desc="getters setters">
  get form(): FormGroup {
    return this._form;
  }

  set form(value: FormGroup) {
    this._form = value;
  }

  get entity(): T {
    return this._entity;
  }

  set entity(value: T) {
    this._entity = value;
  }

  /**
   * Gets the AllowedDataModel used in the handler, allowing to obtain the configuration and all extra metadata for that model
   * Through the AllowedDataModel, the AbstractGenericFormHandler can create genetic mutations always to the correct class,
   * also what graphql query use to get an instance of our model when the action is edit
   *
   * @return IAllowedDataModel
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  get activeAllowedDataModel(): IAllowedDataModel {
    return this._activeAllowedDataModel;
  }

  /**
   * Sets the active model, so that our handler knows how to work correctly
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  set activeAllowedDataModel(value: IAllowedDataModel) {
    this._activeAllowedDataModel = value;
  }

  /**
   * Gets the class from the active AllowedDataModel and returns it in string format
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  entityInstanceToString(): string {
    return DecoratorReflectMetadata.getClassName(this.activeAllowedDataModel.class);
  }

  /**
   * Gets Boolean observable that establish when the form is ready
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  get isFormReady$(): BehaviorSubject<boolean> {
    return this._isFormReady$;
  }

  /**
   * Sets Boolean observable that sets when the form is ready
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  set isFormReady$(value: BehaviorSubject<boolean>) {
    this._isFormReady$ = value;
  }


  /**
   * Gets Boolean observable that establish when the form is processing
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  get isProcessingForm$(): BehaviorSubject<boolean> {
    return this._isProcessingForm$;
  }

  /**
   * Allows you to get the generic service of type GenericCRUDTableService set in the configureCrud() method
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  get crudServiceInstance(): GenericCRUDTableService<T> {
    return this._crudServiceInstance;
  }


  //</editor-fold>

  //<editor-fold desc="Form helper methods ">
  /**
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public isControlInvalid(controlName: string) {
    return FormTools.isControlInvalid(this.form.get(controlName));
  }

  /**
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public isControlValid(controlName: string) {
    return FormTools.isControlValid(this.form.get(controlName));
  }

  /**
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public controlHasError(validation: string, controlName: string) {
    return FormTools.controlHasError(this.form.get(controlName), validation);
  }

  /**
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public getControlError(validation: string, controlName: string) {
    const errors = this.getControlErrors(controlName);
    return errors?.hasOwnProperty(validation) ? errors[validation] : null
  }


  /**
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public getControlErrors(controlName: string) {
    const control = this.form.get(controlName);

    if (null === control) {
      throw new Error('getControlErrors::control cannot be null.')
    }

    return control.errors;
  }

  public controlHasErrorFromApi(controlName: string) {
    let errorKey = this.configureForm().formValidations?.propagateErrorAs ?? PROPAGATE_ERROR_AS
    return FormTools.controlHasError(this.form.get(controlName), errorKey);
  }

  public getControlErrorFromApi(controlName: string) {
    const errors = this.getControlErrors(controlName);
    let errorKey = this.configureForm().formValidations?.propagateErrorAs ?? PROPAGATE_ERROR_AS

    return errors?.hasOwnProperty(errorKey) ? errors[errorKey] : ''
  }

  //</editor-fold>

  //functional methods
  /**
   * Method responsible for processing the sending of the form to the server
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public async onFormSave() {

    if (
      undefined === this.configureForm().formValidations?.validateFieldsOnSubmit ||
      this.configureForm().formValidations?.validateFieldsOnSubmit) {
      //Validates each control within a FormGroup and triggers its validations even when the control was not touched
      this.form.markAllAsTouched()
    }

    //if the form is invalid we just do nothing
    if (this.form.invalid) {
      this.onFormInvalid()
      return;
    }

    let action: string = this.getFormAction()

    if (this.checkFormHasGlobalCustomError(this.form, action)) {
      return;
    }

    this.isFormReady$.next(false);

    let data = this.configureForm().createFromDataDeepClone
      ? HelperTools.deepCopy(this.form.value)
      : this.form.value
    ;

    //We add the id of the entity to the formData so that it is sent as a variable to our mutation
    this.addIdToFromData(action, data);

    // We try to infer which active model according to a simple algorithm
    this.infersAllowedDataModelAutomatically();

    this.isProcessingForm$.next(true)

    //transform data before send
    data = this.dataFormModelTransformerBeforeSend(action, data);

    //allows you to send the updated model data with the form data
    //If false, only the form data is sent
    if (
      undefined !== this.configureForm().sendOnlyFormFields &&
      !this.configureForm().sendOnlyFormFields) {
      data = Object.assign({}, this.entity, data)
    }

    //Execute the event before the mutation of the api resource
    const onBeforeSubmitSuccess = await this.onBeforeSendFormMutation(action, data).catch(reason => reason)
    if (!onBeforeSubmitSuccess) {
      this.isProcessingForm$.next(false)
      return;
    }

    const customMutations = this.activeAllowedDataModel.customMutations
    let allowedDataModelCustomMutation = undefined !== customMutations
      ? customMutations[action] || undefined
      : undefined;

    //create and send mutation
    let mutationResponse = undefined === allowedDataModelCustomMutation
      ? await this.applyGenericMutation(action, data).catch(reason => reason)
      : await this.applyCustomMutation(allowedDataModelCustomMutation, data).catch(reason => reason);

    if (mutationResponse.hasOwnProperty('errors')) {
      let error = ApolloHelperService.getProcessedErrorFromResponse(mutationResponse as ApolloFormError);
      //As mutation failed it can be for several reasons (invalid clientMutationId, internal error or graphql error)
      if (error) {
        console.error('onFormSave', error)
        this.onMutationError(error)
        return;
      }
      //At this point it means that the error must be with the fields of the entity,
      // that is some restriction or validation that the server makes on the entity
      //we use this method to take the error of each of the fields and apply it to each control
      //the error will be propagated in this case with the key "api_error"
      //and can be checked like this [ngIf]="controlHasError('api_error', controlName)"
      if (
        undefined === this.configureForm().formValidations?.applyFieldErrorFromServer
        || this.configureForm().formValidations?.applyFieldErrorFromServer
      ) {
        const propagateErrorAs: any = undefined !== this.configureForm().formValidations?.propagateErrorAs
          ? this.configureForm().formValidations?.propagateErrorAs
          : PROPAGATE_ERROR_AS
        FormTools.addFormGroupErrorsFromMutationResponse(this.form, mutationResponse.errors, propagateErrorAs)
      }

      return;
    }

    this.onMutationSuccess(mutationResponse)

    //Execute the event after the mutation of the api resource is completed
    await this.onAfterSendFormMutation(mutationResponse);

    //we destroy and create the form to clean it
    if (MUTATION_ACTION.create === action && this.configureForm().cleanFormOnCreate) {
      this.createNewEntityInstance();
      this.buildReactiveForm()
    }

  }

  /**
   * Apply the conventional mutation, use the custom mutations set for the active model
   * @param mutation
   * @param data
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public applyCustomMutation(mutation: ApolloMutation, data: any) {
    return this.crudServiceInstance.mutate({
      mutation,
      data,
      isLoading$: this.isProcessingForm$,
      processResult: this.configureForm().processSuccessMutationResult,
      enabledSecurity: true,
    })
  }

  /**
   * Apply a generic mutation with the data from the form configuration
   * @param action
   * @param data
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public applyGenericMutation(action: MutationAction, data: any) {
    const mutationSubQueryFields = this.activeAllowedDataModel.mutationSubQueryFields?.join(', ');
    return this.crudServiceInstance.mutate({
      action,
      instance: this.activeAllowedDataModel.class,
      data,
      isLoading$: this.isProcessingForm$,
      processResult: this.configureForm().processSuccessMutationResult,
      enabledSecurity: true,
      mutationSubQueryFields
    })
  }


  /**
   * Extension point before sending the form mutation, allows establishing a logic before sending the main form
   * and at the same time can prevent the execution of the final mutation.
   * For this we must return "true" to continue or "false" to stop the flow
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public async onBeforeSendFormMutation(action: MutationAction, formData: PartialExtended<T>): Promise<boolean> {
    return true
  }

  /**
   * Extension point after sending the form mutation, allows establishing a logic after sending the main form,
   * This point is always executed whether the sending of the form had an error or was correct.
   * To execute code when the form is correct or not, use the methods "onMutationSuccess" "onMutationError"
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public async onAfterSendFormMutation(result: ApolloMutationResult): Promise<void> {
    return;
  }

  /**
   * Method that is called when the form has been submitted but form invalid property is true
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public onFormInvalid(): void {
  }

  /**
   * This method is called when the mutation returns correctly from the server
   *
   * @param {ApolloMutationResult} result
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public onMutationSuccess(result: ApolloMutationResult): void {
    this.sweetAlertService.success().then()
  }

  /**
   * This method is called when the mutation fails and returns with errors from the server
   *
   * @param {ApolloFormError} error
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public onMutationError(error: { category: string, message?: string }): void {
    // in any of these cases we take the specific value of the message and launch an alert
    this.sweetAlertService.error({
      title: error.category,
      text: error.message
    }).then()

  }

  /**
   * This method is called just before sending the mutation to the server
   * is used to modify the variables before being sent
   *
   * @param {string} action
   * @param {any} formData
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public dataFormModelTransformerBeforeSend(action: MutationAction, formData: PartialExtended<T>): PartialExtended<T> {
    return formData;
  }


  /**
   * Method that is called in the buildReactiveForm after form is created successfully
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public onBuildFormFinish() {
  }

  /**
   * Method that is called in the buildReactiveForm before create a form
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public onBuildFormStart() {
  }


  /**
   * In this method we can make requests to the server for the necessary data in the form, just before build the
   * reactive form
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public async preFetchDataAfterBuildReactiveForm() {
  }


  /**
   * Method that obtains a server instance for the class set in the active AllowedDataModel
   */
  public async findOneInstanceByID() {

    if (undefined !== this.genericEntityId) {

      this.createNewEntityInstance();

      let query = this.activeAllowedDataModel.query

      if (undefined !== query) {
        let queryResponse = await this.crudServiceInstance
          .fetchElementById({
            query,
            id: this.genericEntityId
          })
          .catch(reason => reason)

        if (queryResponse.hasOwnProperty('errors')) {
          this.onFindOneInstanceByIDError(queryResponse);
          return;
        }

        this.onFindOneInstanceByIDSuccess(queryResponse);
      }

    }

  }

  /**
   * Method to extend what to do when an instance was obtained successful from the server
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public onFindOneInstanceByIDSuccess(instance: any) {
    if (null !== instance && undefined !== instance) {
      this.entity.updateFromQuery(instance)
    }
  }

  /**
   * Method to extend what to do when fail to get an instance from the server
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public onFindOneInstanceByIDError(reason: any) {
    console.error('fetchEntityInstance', reason);
    this.sweetAlertService.error({
      text: 'Error al cargar información. Por favor inténtelo mas tarde.'
    }).then()
  }

  /**
   * Allows you to determine how and when to automatically add the id to the formData to be sent in the mutation
   * @param action
   * @param data
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public addIdToFromData(action: MutationAction, data: any) {
    if (MUTATION_ACTION.create !== action) {
      data.id = this.entity.id
    }
  }

  /**
   * Allows you to determine what action the form is performing (create, update)
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public getFormAction(): MutationAction {
    return undefined === this.genericEntityId ? MUTATION_ACTION.create : MUTATION_ACTION.update
  }

  /**
   * This method allows us to validate in a generic and global way any desired value in our form.
   * If the method returns true then the form does not continue its flow in case of false it performs the established actions.
   *
   * Is called just after knowing that form is valid according to the rules applied to each field individually,
   * which makes it our first manual checkpoint.
   *
   * @param {FormGroup} form
   * @param {MutationAction} action
   *
   * @return boolean (default -> false)
   */
  public checkFormHasGlobalCustomError(form: FormGroup, action: MutationAction): boolean {
    return false;
  }

  /**
   * Find and return an allowedDataModel given a class or a string with the class name , or undefined if not found
   * @param instance
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public getAllowedDataModelByClass(instance: any): IAllowedDataModel | undefined {

    let className = 'string' === typeof instance ? instance : DecoratorReflectMetadata.getClassName(instance);

    for (const dataModel of this.configureForm().allowedDataModels) {
      if (DecoratorReflectMetadata.getClassName(dataModel.class) === className) {
        return dataModel
      }
    }

    return undefined
  }

  /**
   * Find and return the allowedDataModel by default, or return undefined if not found
   */
  public getDefaultAllowedDataModel(): IAllowedDataModel | undefined {
    for (const instance of this.configureForm().allowedDataModels) {
      if (instance.default) {
        return instance
      }
    }
  }

  /**
   * Allows setting the active instance through the entity that is created
   * @protected
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  private infersAllowedDataModelAutomatically(): void {
    const allInstances = this.configureForm().allowedDataModels;

    if (undefined !== allInstances) {
      for (const instance of allInstances) {
        if (DecoratorReflectMetadata.getClassName(instance.class) === DecoratorReflectMetadata.getInstanceName(this.entity)) {
          this.activeAllowedDataModel = instance;
          return;
        }
      }
    }

    const defaultAllowedDataModel = this.getDefaultAllowedDataModel();
    if (undefined !== defaultAllowedDataModel) {
      this.activeAllowedDataModel = defaultAllowedDataModel;
    }
  }

  /**
   * Method for creating the form
   * @private
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  private buildReactiveForm() {
    try {
      this.onBuildFormStart()
      this.form = this.buildForm(this.fb);
      // this.isFormReady$.next(true)
      this.onBuildFormFinish()
    } catch (e) {
      console.error('form-component buildReactiveForm()', e);
    }

  }


  /**
   *Allows you to create a new instance for the class to use in the form
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  private createNewEntityInstance() {
    this.entity = new (this.activeAllowedDataModel.class)();
  }
}
