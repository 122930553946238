import {inject, Injectable} from '@angular/core';
import {DocumentNode} from "graphql";
import {TypedDocumentNode} from "@graphql-typed-document-node/core";
import {ApolloQueryResult, OperationVariables} from "@apollo/client/core/types";
import {BehaviorSubject, lastValueFrom, Subscription} from "rxjs";
import {Apollo, gql} from "apollo-angular";
import {HelperTools} from "./helperTools";

/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export const INVALID_MUTATION_ID = 'invalid_clientMutationId';

/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export declare type ApolloFormError = {
  errors: [{
    message: string,
    debugMessage: string,
    extensions: { category: string, violations?: [{ path: string, message: string }] }
  }]
}

/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export declare type ApolloQuery = DocumentNode | TypedDocumentNode<any, OperationVariables>
/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export declare type ApolloMutation = DocumentNode | TypedDocumentNode<any, OperationVariables>
/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export declare type ApolloMutationResult = { data?: any, errors?: ApolloFormError[] }

/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export enum MUTATION_ACTION {
  create = 'create',
  update = 'update',
  delete = 'delete'
}

/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export namespace MUTATION_ACTION {
  /**
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  export function getAllValues() {
    return Object.values(MUTATION_ACTION);
  }

  /**
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  export function isAllowedValue(action: string): boolean {
    // @ts-ignore
    return getAllValues().includes(action);
  }
}

/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export type MutationAction = MUTATION_ACTION | string


@Injectable({
  providedIn: 'root'
})
/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export class ApolloHelperService {

  protected apollo: Apollo = inject(Apollo);

  /**
   * Make a query to the server and return a promise with the value or reject it in case of errors
   * this query is specific to simple result instances found by id parameter
   *
   * @param query
   * @param id
   * @param isLoadingObserver
   * @param processResult
   * @param freezeResults
   * @return Promise
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public async fetchById(
    {
      query,
      id,
      isLoading$,
      processResult = true,
      freezeResults = true
    }: {
      /**
       * Graphql query
       */
      query: ApolloQuery,
      /**
       * ID for searching an exact object
       */
      id: string,
      /**
       * Its process the response object for you and return de collection or object directly, by default is true, in case
       * you want raw response set to false.
       */
      processResult?: boolean,
      /**
       * This options allows you to apply to the response object or collection writable or readonly properties, default value true.
       * @Example
       * When is true you cant modified responses values directly, you will need to clone or create a new object based on the response and then use it
       * to modify the required value
       *
       * If is false, you will be able to modified directly any property in the response object, it may cost more in optimization terms
       *
       */
      freezeResults?: boolean,
      /**
       * Alternative you can pass a boolean observable to the query, and automatically receive the information if query is loading or not
       * it's quite handy when you want to show loading state for a query
       */
      isLoading$?: BehaviorSubject<boolean>
    }): Promise<ApolloQueryResult<any> | any> {
    isLoading$?.next(true);
    return await new Promise<any>((resolve, reject) => {
      const apolloQuery: Subscription = this.apollo.query<any>({
        errorPolicy: 'all',
        query: query,
        variables: {
          id,
        },
      }).subscribe((result) => {
        isLoading$?.next(result?.loading);

        let response = ApolloHelperService.getApolloQueryData({
          result,
          isCollection: false,
        })

        if (undefined !== response) {
          let queryResult = !processResult ? result : response.data;

          if (undefined !== apolloQuery) {
            apolloQuery.unsubscribe();
          }

          return response.success
            ? resolve(freezeResults ? queryResult : HelperTools.deepCopy(queryResult))
            : reject({errors: queryResult});
        }

      });

    })
  }

  /**
   * Make a query to the server and return a promise with the value or reject it in case of errors
   * @param query
   * @param variables
   * @param isLoadingObserver
   * @param isCollection
   * @param processResult
   * @param freezeResults
   * @return Promise
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public async fetch(
    {
      query,
      variables,
      isLoading$,
      isCollection = true,
      processResult = true,
      freezeResults = true
    }: {
      /**
       * Graphql query
       */
      query: ApolloQuery,
      /**
       * Boolean that's allow you to return the data if is a collection or simple query
       */
      isCollection?: boolean,
      /**
       * Graphql query parameters
       */
      variables?: any,
      /**
       * Its process the response object for you and return de collection or object directly, by default is true, in case
       * you want raw response set to false.
       */
      processResult?: boolean,
      /**
       * This options allows you to apply to the response object or collection writable or readonly properties, default value true.
       *
       * When is true you cant modified responses values directly, you will need to clone or create a new object based on the response and then use it
       * to modify the required value
       *
       * If is false, you will be able to modified directly any property in the response object, it may cost more in optimization terms
       *
       */
      freezeResults?: boolean,
      /**
       * Alternative you can pass a boolean observable to the query, and automatically receive the information if query is loading or not
       * it's quite handy when you want to show loading state for a query
       */
      isLoading$?: BehaviorSubject<boolean>
    }
  ): Promise<ApolloQueryResult<any> | any> {
    isLoading$?.next(true);
    return await new Promise<any>((resolve, reject) => {
      const apolloQuery: Subscription = this.apollo.query<any>({
        errorPolicy: "all",
        query,
        variables
      }).subscribe((result) => {

        isLoading$?.next(result?.loading);

        let response: any = ApolloHelperService.getApolloQueryData({
          result,
          isCollection,
        })

        if (undefined !== response) {
          let queryResult = !processResult ? result : response.data;

          if (undefined !== apolloQuery) {
            apolloQuery.unsubscribe();
          }

          return response.success
            ? resolve(freezeResults ? queryResult : HelperTools.deepCopy(queryResult))
            : reject({errors: queryResult});
        }

      });
    });
  }

  /**
   * Perform mutations and return a promise when it returns successfully from the server
   * and rejects it when there are errors, as well as checking the integrity of clientMutationId
   * if specified
   *
   * @param mutation
   * @param variables
   * @param enabledSecurity
   * @param isLoadingObserver
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public async mutate(
    {
      mutation,
      variables,
      isLoading$,
      enabledSecurity = true,
      processResult = true,
    }: {
      mutation: ApolloMutation,
      variables: any,
      isLoading$?: BehaviorSubject<boolean>,
      enabledSecurity?: boolean
      processResult?: boolean
    },
  ): Promise<ApolloMutationResult> {

    isLoading$?.next(true);

    if (enabledSecurity) {
      ApolloHelperService.guaranteeMutationIdInVars(variables)
    }

    return new Promise<{ data: any }>((resolve, reject) => {
      lastValueFrom(this.apollo.mutate({
        errorPolicy: 'all',
        mutation,
        variables
      }))
        .then(({data, loading, errors}) => {

          isLoading$?.next(loading);

          if (undefined !== errors && errors.length > 0) {
            reject({errors});
          }

          // //if there is an answer
          if (undefined !== data) {
            //if security is activated we check that the initial clientMutationId is the same as the server responded
            if (enabledSecurity && ApolloHelperService.getMutationIdFromVariables(variables)
              !== ApolloHelperService.getMutationIdFromResponse(data)) {
              reject({errors: [{extensions: {category: INVALID_MUTATION_ID}}]})
            }

            if (processResult) {
              const apolloMutationData = ApolloHelperService.getApolloMutationData(data);

              if (apolloMutationData?.success) {
                data = apolloMutationData.data;
              }
            }

            resolve({data});
          }
        })
        .catch((reason) => {
          isLoading$?.next(false);
          reject({errors: reason});
        })
    });
  }

  /**
   * Get the clientMutationId of the variables sent to the mutation
   * @param variables
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public static getMutationIdFromVariables(variables: any): string | undefined {
    if (variables.hasOwnProperty('input')) {
      return variables.input.clientMutationId;
    }

    return variables.clientMutationId;
  }

  /***
   * Get the clientMutationId from the server response
   * @param data
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public static getMutationIdFromResponse(data: any): string | undefined {
    let keyMutation = this.getConnectionName(data);
    if (keyMutation) {
      return data[keyMutation]?.clientMutationId
    }
    return undefined
  }

  /**
   *
   * @param isCollection
   * @param result
   */
  public static getApolloQueryData(
    {
      isCollection,
      result,
    }: {
      result: ApolloQueryResult<any>,
      isCollection: boolean,
    }
  ): { success: boolean, data: any } | undefined {

    let {
      data,
      errors,
    } = result;


    if (undefined !== errors) {
      return {
        success: false,
        data: errors
      }
    }

    if (undefined !== data) {
      let apolloConnectionName = this.getConnectionName(data);

      if (apolloConnectionName) {
        return {
          success: true,
          data: isCollection ? data[apolloConnectionName].collection : data[apolloConnectionName]
        }
      }
    }

    return undefined;
  }


  public static getApolloMutationData(data: any): { success: boolean, data: any } | undefined {
    if (undefined !== data) {

      let apolloConnectionName = this.getConnectionName(data);

      if (undefined !== apolloConnectionName) {

        let definitionName = apolloConnectionName;

        const index = definitionName.search(/[A-Z]/); // find the index of the first uppercase letter
        const split = definitionName.slice(index); // get the second part of the string
        const firstLetter = split.charAt(0).toLowerCase(); // get the first letter of the second part and convert it to lowercase
        definitionName = firstLetter + split.slice(1)

        const result = data[apolloConnectionName][definitionName] || undefined;

        return {
          success: undefined !== result,
          data: result,
        }
      }
    }

    return undefined;
  }

  public static getConnectionName(data: any): string | undefined {
    return Object.keys(data)[0];
  }

  /**
   * Returns the error message from ApolloFormError response , if it is of type "internal" o "graphql"
   *
   * @param {ApolloFormError} reason
   * @return string | undefined
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public static getProcessedErrorFromResponse(reason: ApolloFormError): {
    category: string,
    message?: string
  } | undefined {
    const firstReasonElement = reason.errors[0];

    const isInvalidMutation = firstReasonElement.message
      .toLowerCase()
      .replaceAll('"', '')
      .includes('variable $input got invalid');

    if (isInvalidMutation) {
      firstReasonElement.extensions.category = 'graphql'
    }

    switch (firstReasonElement?.extensions?.category?.toLowerCase()) {
      case INVALID_MUTATION_ID.toLowerCase():
        return {
          category: 'INVALID CSRF TOKEN',
          message: 'invalid_client_mutation_id'
        };
      case 'internal':
        return {
          category: firstReasonElement.message,
          message: firstReasonElement.debugMessage,
        };
      case 'graphql':
        return {
          category: firstReasonElement.extensions.category + ' server error',
          message: firstReasonElement.message,
        };
      case undefined:
        return {
          category: 'unknown server error',
          message: firstReasonElement.message,
        };
      default:
        return {
          category: firstReasonElement?.extensions?.category + ' server error',
          message: firstReasonElement.message,
        };
    }
  }


  /**
   * Generates mutations dynamically based on API-PLATFORM standards
   * @param entityName
   * @param action
   * @param queryFields
   * @param suffix
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public static createGenericMutation(entityName: string, action: MutationAction | string, queryFields?: string, suffix?: string) {
    let operationName = action.toString() + entityName;

    suffix = undefined !== suffix ? suffix : 'Instance';

    let queryTemplate = '';
    if (null !== queryFields && undefined !== queryFields) {
      queryTemplate = `${entityName.charAt(0).toLowerCase() + entityName.slice(1)} {
        ${queryFields}
    }
    `
    }

    let template = `mutation ${operationName + suffix} ($input: ${operationName}Input!) {
          ${operationName}(input: $input){
          clientMutationId
          ${queryTemplate}
          }
        }`

    return gql`${template}`
  }

  /***
   * Guarantees that in the mutation variables there is clientMutationId with value
   * otherwise create and assign a default value
   * @param variables
   * @param hashIdLength
   * @private
   *
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  private static guaranteeMutationIdInVars(variables: any, hashIdLength: number = 12): void {
    if (undefined === ApolloHelperService.getMutationIdFromVariables(variables)) {
      const hash = HelperTools.generateHash({
        length: hashIdLength,
        hasSpecialCharacters: true
      });
      if (variables.hasOwnProperty('input')) {
        variables.input.clientMutationId = hash;
      } else {
        variables.clientMutationId = hash;
      }
    }

  }

}
